import { InfoWindow, Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import classNames from 'classnames';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { COMPANY_DEFAULT_CATEGORY_DEVICE } from 'common-active-invest-supervision/dist/src/business/company/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/button/button';
import { Icon } from 'ui/icons';
import { Separator } from 'ui/layout/separator';
import { SeverityBubble } from 'ui/severity-bubble';
import styles from './index.module.scss';

interface IProps {
  name: string;
  position: string;
  numberAlert: number;
  seeMoreCallback: () => void;
  onClick: () => void;
  hasInfoWindow: boolean;
  lat: number;
  lng: number;
  category: COMPANY_DEFAULT_CATEGORY_DEVICE;
  severity?: ALERT_SEVERITY;
  maintainer?: { firstName: string; lastName: string };
  clusterer: Clusterer;
}

function getCategoryIcon(category: COMPANY_DEFAULT_CATEGORY_DEVICE): string {
  switch (category) {
    case COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET:
      return `
        <rect x="10.5" y="10.5" width="14.9998" height="14.9998" rx="3.5" stroke="#333333"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3597 25.9999L17.3597 11.2802L18.6396 11.2802L18.6396 25.9999L17.3597 25.9999Z" fill="#333333"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1208 21.5199C14.7673 21.5199 14.4808 21.2334 14.4808 20.8799L14.4808 15.76C14.4808 15.4065 14.7673 15.12 15.1208 15.12V15.12C15.4742 15.12 15.7607 15.4065 15.7607 15.76L15.7607 20.8799C15.7607 21.2334 15.4742 21.5199 15.1208 21.5199V21.5199Z" fill="#333333"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8791 21.5199C20.5256 21.5199 20.2391 21.2334 20.2391 20.8799L20.2391 15.76C20.2391 15.4065 20.5256 15.12 20.8791 15.12V15.12C21.2325 15.12 21.519 15.4065 21.519 15.76L21.519 20.8799C21.519 21.2334 21.2325 21.5199 20.8791 21.5199V21.5199Z" fill="#333333"/>
      `;
    case COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY:
      return '<path d="M21.6818 12.3751H20.1644V11.2687C20.1644 11.1106 20.0379 11 19.8957 11H17.0664C16.9083 11 16.7977 11.1264 16.7977 11.2687V12.3751H15.2803C14.569 12.3751 14 12.9442 14 13.6554V24.7197C14 25.431 14.5848 26 15.2803 26H21.6818C22.393 26 22.9621 25.431 22.9621 24.7197V13.6554C22.9621 12.9442 22.393 12.3751 21.6818 12.3751ZM21.7608 24.7197C21.7608 24.7671 21.7292 24.7987 21.6818 24.7987H15.2645C15.2171 24.7987 15.1855 24.7671 15.1855 24.7197V13.6554C15.1855 13.608 15.2171 13.5764 15.2645 13.5764H21.666C21.7134 13.5764 21.745 13.608 21.745 13.6554V24.7197H21.7608Z" fill="#333333"/>';
    case COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT:
      return '<path d="M22.6501 25.0625L22.3376 21.3125H21.4375V13.2812C21.4375 11.472 19.9655 10 18.1562 10H13V10.4688C13 11.7611 14.0514 12.8125 15.3437 12.8125C16.4756 12.8125 17.4226 12.0059 17.6404 10.9375H18.1562C19.4486 10.9375 20.5 11.9889 20.5 13.2812V21.3125H19.5999L19.2874 25.0625H18.3125V26H23.625V25.0625H22.6501ZM15.3437 11.875C14.7326 11.875 14.2113 11.4832 14.0178 10.9375H16.6697C16.4762 11.4832 15.9549 11.875 15.3437 11.875ZM20.2282 25.0625L20.4626 22.25H21.4749L21.7093 25.0625H20.2282Z" fill="black"/>';
  }
}

function getSeverityIcon(severity?: ALERT_SEVERITY): string {
  switch (severity) {
    case ALERT_SEVERITY.CRITICAL:
      return '<path d="M32.1415 34.78H30.9415V29.92H32.1415V34.78ZM30.7415 36.3C30.7415 36.0867 30.8182 35.9033 30.9715 35.75C31.1315 35.5967 31.3215 35.52 31.5415 35.52C31.7549 35.52 31.9415 35.5933 32.1015 35.74C32.2615 35.8867 32.3415 36.0667 32.3415 36.28C32.3415 36.4933 32.2615 36.6767 32.1015 36.83C31.9482 36.9833 31.7615 37.06 31.5415 37.06C31.4349 37.06 31.3315 37.04 31.2315 37C31.1382 36.96 31.0549 36.9067 30.9815 36.84C30.9082 36.7733 30.8482 36.6933 30.8015 36.6C30.7615 36.5067 30.7415 36.4067 30.7415 36.3ZM35.1005 34.78H33.9005V29.92H35.1005V34.78ZM33.7005 36.3C33.7005 36.0867 33.7772 35.9033 33.9305 35.75C34.0905 35.5967 34.2805 35.52 34.5005 35.52C34.7138 35.52 34.9005 35.5933 35.0605 35.74C35.2205 35.8867 35.3005 36.0667 35.3005 36.28C35.3005 36.4933 35.2205 36.6767 35.0605 36.83C34.9072 36.9833 34.7205 37.06 34.5005 37.06C34.3938 37.06 34.2905 37.04 34.1905 37C34.0972 36.96 34.0138 36.9067 33.9405 36.84C33.8672 36.7733 33.8072 36.6933 33.7605 36.6C33.7205 36.5067 33.7005 36.4067 33.7005 36.3ZM38.0595 34.78H36.8595V29.92H38.0595V34.78ZM36.6595 36.3C36.6595 36.0867 36.7362 35.9033 36.8895 35.75C37.0495 35.5967 37.2395 35.52 37.4595 35.52C37.6728 35.52 37.8595 35.5933 38.0195 35.74C38.1795 35.8867 38.2595 36.0667 38.2595 36.28C38.2595 36.4933 38.1795 36.6767 38.0195 36.83C37.8662 36.9833 37.6795 37.06 37.4595 37.06C37.3528 37.06 37.2495 37.04 37.1495 37C37.0562 36.96 36.9728 36.9067 36.8995 36.84C36.8262 36.7733 36.7662 36.6933 36.7195 36.6C36.6795 36.5067 36.6595 36.4067 36.6595 36.3Z" fill="white"/>';
    case ALERT_SEVERITY.MAJOR:
      return '<path d="M33.121 34.78H31.921V29.92H33.121V34.78ZM31.721 36.3C31.721 36.0867 31.7977 35.9033 31.951 35.75C32.111 35.5967 32.301 35.52 32.521 35.52C32.7343 35.52 32.921 35.5933 33.081 35.74C33.241 35.8867 33.321 36.0667 33.321 36.28C33.321 36.4933 33.241 36.6767 33.081 36.83C32.9277 36.9833 32.741 37.06 32.521 37.06C32.4143 37.06 32.311 37.04 32.211 37C32.1177 36.96 32.0343 36.9067 31.961 36.84C31.8877 36.7733 31.8277 36.6933 31.781 36.6C31.741 36.5067 31.721 36.4067 31.721 36.3ZM36.08 34.78H34.88V29.92H36.08V34.78ZM34.68 36.3C34.68 36.0867 34.7567 35.9033 34.91 35.75C35.07 35.5967 35.26 35.52 35.48 35.52C35.6933 35.52 35.88 35.5933 36.04 35.74C36.2 35.8867 36.28 36.0667 36.28 36.28C36.28 36.4933 36.2 36.6767 36.04 36.83C35.8867 36.9833 35.7 37.06 35.48 37.06C35.3733 37.06 35.27 37.04 35.17 37C35.0767 36.96 34.9933 36.9067 34.92 36.84C34.8467 36.7733 34.7867 36.6933 34.74 36.6C34.7 36.5067 34.68 36.4067 34.68 36.3Z" fill="white"/>';
    case ALERT_SEVERITY.MINOR:
      return '<path d="M35.1005 34.78H33.9005V29.92H35.1005V34.78ZM33.7005 36.3C33.7005 36.0867 33.7772 35.9033 33.9305 35.75C34.0905 35.5967 34.2805 35.52 34.5005 35.52C34.7138 35.52 34.9005 35.5933 35.0605 35.74C35.2205 35.8867 35.3005 36.0667 35.3005 36.28C35.3005 36.4933 35.2205 36.6767 35.0605 36.83C34.9072 36.9833 34.7205 37.06 34.5005 37.06C34.3938 37.06 34.2905 37.04 34.1905 37C34.0972 36.96 34.0138 36.9067 33.9405 36.84C33.8672 36.7733 33.8072 36.6933 33.7605 36.6C33.7205 36.5067 33.7005 36.4067 33.7005 36.3Z" fill="white"/>';
    default:
      return '<path d="M33.432 38.0653C32.888 38.6094 32.0052 38.6094 31.4614 38.0653L28.4081 35.012C27.864 34.4682 27.864 33.5854 28.4081 33.0416C28.9519 32.4975 29.8347 32.4975 30.3787 33.0416L32.1979 34.8605C32.3352 34.9976 32.5582 34.9976 32.6958 34.8605L37.6215 29.9347C38.1653 29.3907 39.0481 29.3907 39.5922 29.9347C39.8535 30.196 40.0003 30.5505 40.0003 30.9199C40.0003 31.2894 39.8535 31.6439 39.5922 31.9051L33.432 38.0653Z" fill="white"/>';
  }
}

function getSeverityColor(severity?: ALERT_SEVERITY) {
  switch (severity) {
    case ALERT_SEVERITY.CRITICAL:
      return '#FF3333';
    case ALERT_SEVERITY.MAJOR:
      return '#FFA500';
    case ALERT_SEVERITY.MINOR:
      return '#FFDE33';
    default:
      return '#00CD83';
  }
}

export function DeviceMarker({
  onClick,
  hasInfoWindow,
  name,
  position,
  numberAlert,
  seeMoreCallback,
  category,
  severity,
  maintainer,
  lat,
  lng,
  clusterer,
}: IProps) {
  const { t } = useTranslation();
  const [mapMarker, setMapMarker] = useState<google.maps.Marker | null>(null);
  const markerSvg = `
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="16" fill="white"/>
      <circle cx="18" cy="18" r="17" stroke="black" stroke-opacity="0.1" stroke-width="2"/>
      ${getCategoryIcon(category)}
      <circle cx="34" cy="34" r="10" fill="${getSeverityColor(severity)}"/>
      ${getSeverityIcon(severity)}
    </svg>
  `;

  return (
    <Marker
      position={{
        lat,
        lng,
      }}
      onClick={onClick}
      clusterer={clusterer}
      icon={{
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(markerSvg)}`,
      }}
      onLoad={setMapMarker}
    >
      {hasInfoWindow && mapMarker ? (
        <InfoWindow anchor={mapMarker} onCloseClick={onClick}>
          <div className={styles.markerContainer}>
            <div
              className={classNames(
                styles.name,
                numberAlert > 0 ? styles.withAlert : styles.withoutAlert,
              )}
            >
              {name}
            </div>

            <div className={styles.rowIconText}>
              <Icon width={20} height={20}>
                chart-pie
              </Icon>
              <div className={styles.details}>{position}</div>
            </div>
            <Separator />

            <div
              className={styles.rowIconText}
              style={{ position: 'relative' }}
            >
              <Icon width={20} height={20}>
                notifications
              </Icon>
              {numberAlert > 0 ? (
                <>
                  <div className={classNames(styles.details, styles.withAlert)}>
                    {t('device.list.alertWithCount', { count: numberAlert })}
                  </div>
                  {severity ? (
                    <SeverityBubble
                      severity={severity}
                      className={styles.bubble}
                    />
                  ) : null}
                </>
              ) : (
                <div className={styles.details}>
                  {t('device.list.alertWithoutCount')}
                </div>
              )}
            </div>

            {maintainer && (
              <>
                <Separator />
                <div
                  className={styles.rowIconText}
                  title={t('common.maintainer')}
                >
                  <Icon width={20} height={20}>
                    settings
                  </Icon>
                  <div id="maintainerName" className={styles.details}>
                    {`${maintainer.firstName} ${maintainer.lastName}`}
                  </div>
                </div>
              </>
            )}

            <Button
              colorMode="stroke"
              className={styles.button}
              onClick={seeMoreCallback}
            >
              {t('maps.markerSeeMore')}
            </Button>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
}
