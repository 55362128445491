"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var UserRole;

(function (UserRole) {
  UserRole["GROUP_MANAGER"] = "group_manager";
  UserRole["COMPANY_MANAGER"] = "company_manager";
  UserRole["CLIENT_MANAGER"] = "client_manager";
  UserRole["MAINTAINER"] = "maintainer";
  UserRole["GROUP_OBSERVER"] = "group_observer";
  UserRole["COMPANY_OBSERVER"] = "company_observer";
  UserRole["CLIENT_OBSERVER"] = "client_observer";
})(UserRole = exports.UserRole || (exports.UserRole = {}));

var UserLocale;

(function (UserLocale) {
  UserLocale["FR"] = "fr-fr";
  UserLocale["EN"] = "en-gb";
})(UserLocale = exports.UserLocale || (exports.UserLocale = {}));