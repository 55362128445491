import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { InputText } from '../../../../ui/form/inputText';

import { useTranslation } from 'react-i18next';
import { siteAddValidation } from './service';
import styles from '../../../../ui/styles/add-form.module.scss';
import { post, updateSite } from '../../services/api';
import classNames from 'classnames';
import { SectionTitle } from 'ui/typo/section-title';
import { Select } from 'ui/form/select';
import { Modal } from 'ui/modal';
import { IClientV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { ISiteV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { listClients } from 'business/client/services/api';
import { SiteAddValues } from './index';
import FormClientAdd from 'business/client/pages/add/form';
import { Loader } from 'ui/loader';
import { redirectWithSuccess } from 'technical/flash-message';
import { Button } from 'ui/button/button';

const FormSiteAdd: React.FC<{
  onAdded?: (createdSite: ISiteV1Response) => void;
  canCreateEntity: boolean;
  initialData?: SiteAddValues;
  modifyId?: string;
}> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [clients, setClients] = useState<IClientV1Response[]>([]);
  const [modalClientOpen, setModalClientOpen] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      try {
        setIsFetching(true);
        const { items } = await listClients({ page: 1, limit: 100 });
        setClients(items);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getData();
  }, []);

  return (
    <>
      <SectionTitle
        text={props.modifyId ? t('site.modify.title') : t('site.add.title')}
      />

      {isFetching ? (
        <Loader />
      ) : (
        <Formik<SiteAddValues>
          initialValues={
            props.initialData
              ? props.initialData
              : {
                  clientId: '',
                  name: '',
                  latitude: '',
                  longitude: '',
                }
          }
          onSubmit={async (values: SiteAddValues) => {
            setLoading(true);
            setError(null);
            try {
              if (props.modifyId) {
                await updateSite(props.modifyId, {
                  clientId: values.clientId,
                  name: values.name,
                  location: `${values.latitude},${values.longitude}`,
                });
                redirectWithSuccess('/sites', 'site.modify.form.success');
              } else {
                const createdSite = await post({
                  clientId: values.clientId,
                  name: values.name,
                  location: `${values.latitude},${values.longitude}`,
                });
                props.onAdded
                  ? props.onAdded(createdSite)
                  : redirectWithSuccess('/sites', 'site.add.form.success');
              }
            } catch (e) {
              setError(t('site.add.form.error'));
            } finally {
              setLoading(false);
            }
          }}
          validate={siteAddValidation}
        >
          <Form autoComplete="off">
            <div className={styles.canAddRow}>
              <Field
                className={styles.inputText}
                name="clientId"
                placeholder={t('site.add.form.clientId.placeholder')}
                component={Select}
                options={clients.map(client => ({
                  value: client.id,
                  label: client.name,
                }))}
              />
              {props.canCreateEntity && (
                <button
                  className={styles.addButton}
                  onClick={e => {
                    e.preventDefault();
                    setModalClientOpen(true);
                  }}
                >
                  +
                </button>
              )}
            </div>

            <Field
              name="name"
              placeholder={t('site.add.form.name.placeholder')}
              component={InputText}
              className={styles.inputText}
            />

            <Field
              name="latitude"
              type="number"
              step="any"
              placeholder={t('site.add.form.latitude.placeholder')}
              component={InputText}
              className={styles.inputText}
            />
            <Field
              name="longitude"
              type="number"
              step="any"
              placeholder={t('site.add.form.longitude.placeholder')}
              component={InputText}
              className={styles.inputText}
            />
            <div className={styles.actions}>
              <Button disabled={loading} type="submit">
                {props.modifyId
                  ? t('site.modify.form.submit')
                  : t('site.add.form.submit')}
              </Button>
            </div>
            {error !== null && (
              <div className={classNames(styles.errors)}>{error}</div>
            )}
          </Form>
        </Formik>
      )}

      <Modal
        isOpen={modalClientOpen}
        onRequestClose={() => setModalClientOpen(false)}
      >
        <FormClientAdd
          onAdded={createdClient => {
            setModalClientOpen(false);
            setClients([...clients, createdClient]);
          }}
        />
      </Modal>
    </>
  );
};

export default FormSiteAdd;
