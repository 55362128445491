import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Redirect, useHistory, useParams } from 'react-router';
import FormCompanyAdd from '../add/form';
import { getCompany } from 'business/company/services/api';
import { ICompanyDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/company/api/v1';
import { Loader } from 'ui/loader';

const CompanyModify: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();
  const { id } = useParams();
  const [company, setCompany] = useState<ICompanyDetailedV1Response>();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getCompany(id);
          setCompany(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  if (
    user &&
    ![UserRole.COMPANY_MANAGER, UserRole.GROUP_MANAGER].includes(user.role)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {company ? (
            <FormCompanyAdd
              initialData={{
                name: company.name,
                defaultDeviceCategory: company.defaultDeviceCategory,
                email: company.email || '',
                phone: company.phone || '',
              }}
              modifyId={id}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default CompanyModify;
