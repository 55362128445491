import React from 'react';

interface Props {
  color: string;
}

export function TrashCan({ color }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M6.25 2V0.25H9.75V2" stroke={color} strokeMiterlimit="10" />
        <path
          d="M3.5 2.5V15.75H12.5V2.5"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path d="M2.5 2.25H13.5" stroke={color} strokeMiterlimit="10" />
        <path d="M9.75 10.75L6.25 7.25" stroke={color} strokeMiterlimit="10" />
        <path d="M6.25 10.75L9.75 7.25" stroke={color} strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
