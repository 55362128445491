"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var validate = require('validate.js'); // Validation rules should all support custom messages injection at runtime


validate.options = {
  fullMessages: false
};
var REQUIRED_MESSAGE_KEY = 'required';
var ENUM_MESSAGE_KEY = 'not_accepted_value';
exports.ALPHABETICAL_MESSAGE_KEY = 'only_alphanumeric_accepted';
exports.PASSWORD_IS_TOO_WEAK = 'password_is_too_weak'; // Default traduction keys for validation error messages

var DefaultKeys;

(function (DefaultKeys) {
  DefaultKeys["REQUIRED"] = "required";
  DefaultKeys["ALPHABETICAL"] = "not_alphabetical";
  DefaultKeys["LENGTH_RANGE_LONG"] = "too_long";
  DefaultKeys["LENGTH_RANGE_SHORT"] = "too_short";
  DefaultKeys["WRONG_EMAIL_FORMAT"] = "wrong_email_format";
  DefaultKeys["WRONG_PASSWORD_FORMAT"] = "wrong_password_format";
  DefaultKeys["WRONG_NUMBER_FORMAT"] = "wrong_number_format";
  DefaultKeys["NOT_GREATER_THAN_OR_EQUAL_TO"] = "not_gte";
  DefaultKeys["NOT_LESS_THAN_OR_EQUAL_TO"] = "not_lte";
  DefaultKeys["NOT_CHECKED"] = "not_checked";
  DefaultKeys["WRONG_BOOLEAN_FORMAT"] = "wrong_boolean_format";
  DefaultKeys["WRONG_PHONE_FORMAT"] = "wrong_phone_format";
  DefaultKeys["NOT_IN_ENUM"] = "not_in_enum";
})(DefaultKeys || (DefaultKeys = {}));

var ValidatorPatterns = {
  ALPHABETICAL_PATTERN: /^[^±!@£$%^&*+§¡€#¢§¶•ªº«\\/<>?:;|=€©\d]{0,250}$/,
  PHONE_NUMBER_PATTERN: /^(0|(\+33)|(0033)|(\+330)|(\+33\(0\)))[1-9]([0-9]{8})$/,
  PASSWORD_PATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
};

exports.REQUIRED = function () {
  return {
    presence: {
      message: REQUIRED_MESSAGE_KEY,
      allowEmpty: false
    }
  };
};

exports.ALPHABETICAL = function () {
  return {
    format: {
      message: exports.ALPHABETICAL_MESSAGE_KEY,
      pattern: ValidatorPatterns.ALPHABETICAL_PATTERN,
      flags: 'i'
    }
  };
};

exports.INTEGER = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_NUMBER_FORMAT;
  var notGreaterThanOrEqualToMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultKeys.NOT_GREATER_THAN_OR_EQUAL_TO;
  var notLessThanOrEqualToMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultKeys.NOT_LESS_THAN_OR_EQUAL_TO;
  return {
    numericality: {
      onlyInteger: true,
      notValid: message,
      notInteger: message,
      notGreaterThanOrEqualTo: notGreaterThanOrEqualToMessage,
      notLessThanOrEqualTo: notLessThanOrEqualToMessage,
      noStrings: true
    }
  };
};

exports.NUMBER = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_NUMBER_FORMAT;
  var notGreaterThanOrEqualToMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultKeys.NOT_GREATER_THAN_OR_EQUAL_TO;
  var notLessThanOrEqualToMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultKeys.NOT_LESS_THAN_OR_EQUAL_TO;
  return {
    numericality: {
      onlyInteger: false,
      notValid: message,
      notGreaterThanOrEqualTo: notGreaterThanOrEqualToMessage,
      notLessThanOrEqualTo: notLessThanOrEqualToMessage,
      noStrings: true
    }
  };
};

exports.BOOLEAN = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_BOOLEAN_FORMAT;
  return {
    inclusion: {
      message: message,
      within: [true, false]
    }
  };
};

exports.INTEGER_RANGE = function (min, max) {
  var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultKeys.WRONG_NUMBER_FORMAT;
  return {
    numericality: Object.assign(Object.assign({}, exports.INTEGER(message).numericality), {
      message: message,
      greaterThanOrEqualTo: min,
      lessThanOrEqualTo: max
    })
  };
};

exports.LENGTH_RANGE = function (min, max) {
  var messageShort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultKeys.LENGTH_RANGE_SHORT;
  var messageLong = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DefaultKeys.LENGTH_RANGE_LONG;
  return {
    length: {
      minimum: min,
      maximum: max,
      tooShort: messageShort,
      tooLong: messageLong,
      getVariables: function getVariables() {
        return {
          minimum: min,
          maximum: max
        };
      }
    }
  };
};

exports.EMAIL = function () {
  return {
    email: true
  };
};

exports.ENUM = function (enumObject) {
  return {
    inclusion: {
      message: ENUM_MESSAGE_KEY,
      within: Object.values(enumObject)
    }
  };
};

exports.INTEGER_STRING = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_NUMBER_FORMAT;
  var notGreaterThanOrEqualToMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultKeys.NOT_GREATER_THAN_OR_EQUAL_TO;
  var notLessThanOrEqualToMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultKeys.NOT_LESS_THAN_OR_EQUAL_TO;
  return {
    numericality: {
      onlyInteger: true,
      notValid: message,
      notInteger: message,
      notGreaterThanOrEqualTo: notGreaterThanOrEqualToMessage,
      notLessThanOrEqualTo: notLessThanOrEqualToMessage,
      noStrings: false
    }
  };
};

exports.CHECKED = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.NOT_CHECKED;
  return {
    inclusion: {
      message: message,
      within: [true]
    }
  };
};

exports.INTEGER_STRING_RANGE = function (min, max) {
  var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DefaultKeys.WRONG_NUMBER_FORMAT;
  return {
    numericality: Object.assign(Object.assign({}, exports.INTEGER_STRING(message).numericality), {
      greaterThanOrEqualTo: min,
      lessThanOrEqualTo: max
    })
  };
};

exports.ZIP_CODE = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_NUMBER_FORMAT;
  return Object.assign(Object.assign({}, exports.LENGTH_RANGE(5, 5)), {
    numericality: {
      notValid: message
    }
  });
};

exports.PHONE = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_PHONE_FORMAT;
  return {
    format: {
      message: message,
      pattern: ValidatorPatterns.PHONE_NUMBER_PATTERN,
      flags: 'i'
    }
  };
};
/**
 * Validates if password has 8 characeters, 1 CAP, 1 Lower case and 1 number
 */


validate.validators.password = function (password, options) {
  var passwordValidator = new RegExp(ValidatorPatterns.PASSWORD_PATTERN);

  if (!passwordValidator.test(password)) {
    return options.message;
  }
};

validate.validators.requiredDependingOn = function (_value, options, key, attributes) {
  if (!attributes[key] && !attributes[options.key]) {
    return 'required';
  }

  return null;
};

validate.validators.optionalEmail = function (value) {
  if (value === '') {
    return null;
  }

  return validate.single(value, {
    email: true
  });
};

validate.validators.requiredForRole = function (_value, options, key, attributes) {
  if (!attributes[key] && options.requiredRole.includes(attributes[options.key])) {
    return 'required';
  }

  return null;
};
/**
 * Defines the PASSWORD validation rule, passes a { message } options object to the validator passwor
 */


exports.PASSWORD = function () {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultKeys.WRONG_PASSWORD_FORMAT;
  return {
    password: {
      message: message
    }
  };
};

var LAT_LNG_FORMAT = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;
exports.LOCATION = {
  format: {
    pattern: LAT_LNG_FORMAT,
    message: 'incorrect_format'
  }
};