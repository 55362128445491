import { WindowSize } from 'technical/media/types';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '../config';

export function isMobile(windowSize: WindowSize) {
  return windowSize.innerWidth < MOBILE_BREAKPOINT;
}

export function isUnderDesktop(windowSize: WindowSize) {
  return windowSize.innerWidth < TABLET_BREAKPOINT;
}

export function isTablet(windowSize: WindowSize, window: Window) {
  // If the screen is wider than 1024px we test if it matches (pointer: coarse) mediaQuery (touch device)
  const { matches } = window.matchMedia('(pointer: coarse)');

  return (
    (windowSize.innerWidth >= MOBILE_BREAKPOINT &&
      windowSize.innerWidth < TABLET_BREAKPOINT) ||
    (windowSize.innerWidth >= TABLET_BREAKPOINT && matches)
  );
}
