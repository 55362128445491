import { acknowledgeAlarm } from 'business/alarm/services/api';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import classNames from 'classnames';
import { ALARM_STATUS } from 'common-active-invest-supervision/dist/src/business/alarm/types';
import { IDeviceDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { factoryDateTime } from 'common-active-invest-supervision/dist/src/technical/service/datetime';
import fr from 'date-fns/locale/fr';
import { stringify } from 'querystring';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { formatDateL, formatDateLT } from 'technical/date';
import {
  redirectWithError,
  redirectWithSuccess,
} from 'technical/flash-message';
import { Button } from 'ui/button/button';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';
import datePickerStyles from 'ui/form/date-picker/index.module.scss';
import { Icon } from 'ui/icons';
import { Loader } from 'ui/loader';
import { SectionTitle } from 'ui/typo/section-title';
import BasePage from '../../../base-page';
import { deleteDevice, getDevice } from '../../services/api';
import styles from './index.module.scss';

const DeviceView: React.FC<ReactNode> = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [deviceData, setDeviceData] = useState<IDeviceDetailedV1Response>();
  const { user } = useUserContext();
  const history = useHistory();
  const dateTime = factoryDateTime();
  const [endDate, setEndDate] = useState<Date>(dateTime.getNow());
  const [startDate, setStartDate] = useState<Date>(dateTime.subXDaysAgo(7));

  const fetchData = useCallback(async () => {
    if (id) {
      const result = await getDevice(id);
      setDeviceData(result);
    }
  }, [id]);

  useEffect(() => {
    registerLocale('fr-FR', fr);
    fetchData();
  }, [fetchData]);

  const buttons = (
    <>
      {user &&
      [UserRole.COMPANY_MANAGER, UserRole.CLIENT_MANAGER].includes(
        user.role,
      ) ? (
        <ModifyButton
          className={styles.modifyButton}
          onClick={() => history.push(`/device/modify/${id}`)}
        >
          {t('device.view.modify')}
        </ModifyButton>
      ) : null}

      {user && user.role === UserRole.COMPANY_MANAGER ? (
        <DeleteButton
          className={styles.modifyButton}
          onClick={async () => {
            try {
              if (window.confirm(t('device.delete.confirm'))) {
                await deleteDevice(String(id));
                redirectWithSuccess('/devices', 'device.delete.success');
              }
            } catch (e) {
              redirectWithError('/devices', 'device.delete.error');
            }
          }}
        >
          {t('device.view.delete')}
        </DeleteButton>
      ) : null}
    </>
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <div className={styles.flex}>
            <button
              onClick={() => {
                const urlParams = new URLSearchParams(window.location.search);
                const comeFromListDevice = urlParams.get('from-devices');
                const client = urlParams.get('client');
                const site = urlParams.get('site');
                const deviceType = urlParams.get('deviceType');
                const deviceName = urlParams.get('deviceName');

                const filters = stringify({
                  client,
                  site,
                  deviceType,
                  deviceName,
                });
                history.push(
                  comeFromListDevice ? `/devices?${filters}` : '/alarms',
                );
              }}
              className={styles.returnButton}
            >
              &lt; {t('common.return')}
            </button>
          </div>

          {deviceData ? (
            <>
              <div className={styles.headerContainer}>
                <SectionTitle
                  text={`${deviceData.description} (${deviceData.deviceType.name})`}
                />
                <div className={styles.mainButton}>{buttons}</div>
              </div>
              {deviceData.alarms
                .filter(alarm =>
                  [
                    ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED,
                    ALARM_STATUS.OPEN_ACKNOWLEDGED,
                  ].includes(alarm.status),
                )
                .map((alarm, index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.alertDialog,
                      ALARM_STATUS.OPEN_ACKNOWLEDGED === alarm.status
                        ? styles.alertAcknowlegedDialog
                        : null,
                    )}
                  >
                    <span className={styles.alertText}>
                      {t('device.view.opened', {
                        alertName: alarm.alert.name,
                        date: formatDateL(alarm.creationTime),
                        time: formatDateLT(alarm.creationTime),
                      })}
                    </span>

                    {ALARM_STATUS.OPEN_ACKNOWLEDGED === alarm.status &&
                    alarm.acknowledgeBy &&
                    alarm.acknowledgeTime ? (
                      <span className={styles.alertText}>
                        {t('device.view.acknowledged', {
                          firstName: alarm.acknowledgeBy.firstName,
                          lastName: alarm.acknowledgeBy.lastName,
                          date: formatDateL(alarm.acknowledgeTime),
                          time: formatDateLT(alarm.acknowledgeTime),
                        })}
                      </span>
                    ) : null}

                    {ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED === alarm.status &&
                      user &&
                      [
                        UserRole.COMPANY_MANAGER,
                        UserRole.CLIENT_MANAGER,
                        UserRole.MAINTAINER,
                      ].includes(user.role) && (
                        <Button
                          colorRole="danger"
                          colorMode="stroke"
                          className={styles.alertButton}
                          onClick={async () => {
                            try {
                              await acknowledgeAlarm(alarm.id);
                              fetchData();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {t('device.view.free')}
                        </Button>
                      )}
                  </div>
                ))}
              <div className={styles.informationPanel}>
                <div className={styles.rowIconText}>
                  <Icon width={16} height={16}>
                    location
                  </Icon>
                  <div id="siteName" className={styles.details}>
                    <div>{deviceData.site.name}</div>
                    <div>{deviceData.site.client.name}</div>
                    <div>
                      {deviceData.location
                        ? deviceData.location.x
                        : deviceData.site.location.x}
                      ,{' '}
                      {deviceData.location
                        ? deviceData.location.y
                        : deviceData.site.location.y}
                    </div>
                  </div>
                </div>
                <div>
                  {deviceData.maintainer && (
                    <div
                      className={styles.rowIconText}
                      title={t('common.maintainer')}
                    >
                      <Icon width={16} height={16}>
                        settings
                      </Icon>
                      <div id="maintainerName" className={styles.details}>
                        <div className={styles.title}>
                          {t('common.maintainer')}
                        </div>
                        {`${deviceData.maintainer.firstName} ${deviceData.maintainer.lastName}`}
                        {deviceData.maintainer.phone
                          ? `\n${deviceData.maintainer.phone}`
                          : ''}
                        {deviceData.maintainer.email
                          ? `\n${deviceData.maintainer.email}`
                          : ''}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.grafanaTimeSelection}>
                  <span>{t('device.view.from')}</span>
                  <DatePicker
                    className={styles.inputText}
                    selected={startDate}
                    dayClassName={date =>
                      startDate && date.getDate() === startDate.getDate()
                        ? datePickerStyles.selectedDay
                        : ''
                    }
                    onChange={(date: Date) => setStartDate(date)}
                    locale={i18n.language}
                    showTimeSelect
                    dateFormat="Pp"
                  />
                  <span>{t('device.view.to')}</span>
                  <DatePicker
                    className={styles.inputText}
                    selected={endDate}
                    dayClassName={date =>
                      endDate && date.getDate() === endDate.getDate()
                        ? datePickerStyles.selectedDay
                        : ''
                    }
                    onChange={(date: Date) => setEndDate(date)}
                    locale={i18n.language}
                    showTimeSelect
                    dateFormat="Pp"
                  />
                </div>
                {deviceData.dashboardUrl && (
                  <iframe
                    title={`graphana-dashboard`}
                    className={styles.grafanaFrame}
                    src={`${
                      deviceData.dashboardUrl
                    }&from=${startDate.getTime()}&to=${endDate.getTime()}`}
                  />
                )}
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceView;
