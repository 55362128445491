import validate from 'validate.js';
import { CompanyAddValues } from '../../add/form';
import { REQUIRED, EMAIL } from '../../../../../technical/validation/rules';
import { i18n } from '../../../../../bootstrap/i18n';

interface Constraints {
  name: {};
  email?: {};
}

export const companyAddValidation = (values: CompanyAddValues) => {
  const constraints: Constraints = {
    name: REQUIRED(i18n.t('company.add.form.name.required')),
  };

  if (values.email) {
    constraints.email = EMAIL(i18n.t('company.add.form.email.invalidFormat'));
  }

  return validate(values, constraints);
};
