import React from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Redirect, useHistory } from 'react-router';
import FormDeviceAdd from './form';

const DeviceAdd: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();

  if (user && user.role !== UserRole.COMPANY_MANAGER) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          <FormDeviceAdd />
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceAdd;
