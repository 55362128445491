import React, { HTMLAttributes } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Separator } from 'ui/layout/separator';

interface IProps extends HTMLAttributes<HTMLHeadingElement> {
  text: string;
}

const SectionTitle: React.FC<IProps> = ({
  children,
  className = '',
  text,
  ...props
}) => {
  return (
    <div className={styles.container}>
      <h1 className={classNames(styles.sectionTitle, className)} {...props}>
        {text}
      </h1>
      <Separator className={styles.separator} />
    </div>
  );
};

export { SectionTitle };
