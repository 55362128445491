import validate from 'validate.js';
import { SiteAddValues } from '../../add';
import { REQUIRED, NUMBER } from '../../../../../technical/validation/rules';
import { i18n } from '../../../../../bootstrap/i18n';

export const siteAddValidation = ({
  clientId,
  name,
  latitude,
  longitude,
}: SiteAddValues) => {
  const attributes = {
    clientId,
    name,
    latitude,
    longitude,
  };
  const constraints = {
    clientId: REQUIRED(i18n.t('site.add.form.clientId.required')),
    name: REQUIRED(i18n.t('site.add.form.name.required')),
    latitude: {
      ...REQUIRED(i18n.t('site.add.form.latitude.required')),
      ...NUMBER(i18n.t('site.add.form.latitude.error')),
    },
    longitude: {
      ...REQUIRED(i18n.t('site.add.form.longitude.required')),
      ...NUMBER(i18n.t('site.add.form.longitude.error')),
    },
  };

  return validate(attributes, constraints);
};
