import React from 'react';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import BasePage from 'business/base-page';
import styles from 'ui/styles/add-form.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import FormCompanyAdd from './form';

export default function CompanyAdd() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();

  if (user && user.role !== UserRole.GROUP_MANAGER) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>
          <FormCompanyAdd />
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}
