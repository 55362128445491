"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var COMPANY_DEFAULT_CATEGORY_DEVICE;

(function (COMPANY_DEFAULT_CATEGORY_DEVICE) {
  COMPANY_DEFAULT_CATEGORY_DEVICE["ELECTRICAL_CABINET"] = "ELECTRICAL_CABINET";
  COMPANY_DEFAULT_CATEGORY_DEVICE["BATTERY"] = "BATTERY";
  COMPANY_DEFAULT_CATEGORY_DEVICE["LIGHT"] = "LIGHT";
})(COMPANY_DEFAULT_CATEGORY_DEVICE = exports.COMPANY_DEFAULT_CATEGORY_DEVICE || (exports.COMPANY_DEFAULT_CATEGORY_DEVICE = {}));