import history from 'technical/history';

export function redirectWithSuccess(
  path: string,
  messageTranslationKey: string,
) {
  history.push(path, { flashMessageSuccess: messageTranslationKey });
}

export function redirectWithError(path: string, messageTranslationKey: string) {
  history.push(path, { flashMessageError: messageTranslationKey });
}
