import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { InputText } from '../../../../ui/form/inputText';

import { useTranslation } from 'react-i18next';
import { devicetypeAddValidation } from './service';
import styles from '../../../../ui/styles/add-form.module.scss';
import { post, updateDeviceType } from '../../services/api';
import classNames from 'classnames';
import { SectionTitle } from 'ui/typo/section-title';
import { redirectWithSuccess } from 'technical/flash-message';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { Button } from 'ui/button/button';
import { DeviceTypeNetwork } from 'common-active-invest-supervision/dist/src/business/deviceType/types';
import { Select } from '../../../../ui/form/select';

export type DeviceTypeAddValues = {
  name: string;
  dashboardUrl: string;
  network: DeviceTypeNetwork;
};

const FormDeviceTypeAdd: React.FC<{
  onAdded?: (createdDeviceType: IDeviceTypeV1Response) => void;
  initialData?: DeviceTypeAddValues;
  modifyId?: string;
}> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      <SectionTitle
        text={
          props.modifyId
            ? t('devicetype.modify.title')
            : t('devicetype.add.title')
        }
      />

      <div>
        <Formik<DeviceTypeAddValues>
          initialValues={
            props.initialData
              ? props.initialData
              : {
                  name: '',
                  dashboardUrl: '',
                  network: DeviceTypeNetwork.SIGFOX,
                }
          }
          onSubmit={async (values: DeviceTypeAddValues) => {
            setLoading(true);
            setError(null);
            try {
              if (props.modifyId) {
                await updateDeviceType(props.modifyId, values);
                redirectWithSuccess(
                  '/device-types',
                  'devicetype.modify.form.success',
                );
              } else {
                const createdDeviceType = await post(values);
                props.onAdded
                  ? props.onAdded(createdDeviceType)
                  : redirectWithSuccess(
                      '/device-types',
                      'devicetype.add.form.success',
                    );
              }
            } catch (e) {
              setError(t('devicetype.add.form.error'));
            } finally {
              setLoading(false);
            }
          }}
          validate={devicetypeAddValidation}
        >
          <Form autoComplete="off">
            <Field
              name="name"
              placeholder={t('devicetype.add.form.name.placeholder')}
              component={InputText}
              className={styles.inputText}
            />
            <Field
              name="dashboardUrl"
              placeholder={t('devicetype.add.form.dashboardUrl.placeholder')}
              component={InputText}
              className={styles.inputText}
            />
            <Field
              name="network"
              placeholder={t('devicetype.add.form.network.placeholder')}
              component={Select}
              options={Object.values(DeviceTypeNetwork).map(
                (deviceTypeNetwork: string) => ({
                  value: deviceTypeNetwork,
                  label: deviceTypeNetwork,
                }),
              )}
              className={styles.inputText}
            />
            <div className={styles.actions}>
              <Button disabled={loading} type="submit">
                {props.modifyId
                  ? t('devicetype.modify.form.submit')
                  : t('devicetype.add.form.submit')}
              </Button>
            </div>
            {error !== null && (
              <div className={classNames(styles.errors)}>{error}</div>
            )}
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default FormDeviceTypeAdd;
