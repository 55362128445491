import { fetchDevicesLight } from 'business/device/services/api';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import {
  ALERT_TRIGGER_PERIOD_TYPE,
  ANOMALY_OPERATOR,
} from 'common-active-invest-supervision/dist/src/business/alert/types';
import { IDeviceV1ResponseLight } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { Formik } from 'formik';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import { redirectWithSuccess } from 'technical/flash-message';
import { Loader } from 'ui/loader';
import { SectionTitle } from 'ui/typo/section-title';
import styles from '../../../../ui/styles/add-form.module.scss';
import BasePage from '../../../base-page';
import { post } from '../../services/api';
import { isAllowed } from '../list';
import Form from './form';
import { alertAddValidation } from './service';

export type Watcher = {
  isActivated: boolean;
  value: string;
};

function AlertAdd() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();
  const [watchers, setWatchers] = useState<Watcher[]>([
    { isActivated: false, value: '' },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [devices, setDevices] = useState<IDeviceV1ResponseLight[]>([]);
  const deviceTypes = uniqBy(devices.map(d => d.deviceType), 'id');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getDevices() {
      try {
        setIsFetching(true);
        const devices = await fetchDevicesLight();
        if (devices) {
          setDevices(devices.items);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getDevices();
  }, []);

  if (user && !isAllowed(user)) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>
          <SectionTitle text={t('alert.add.title')} />

          {isFetching ? (
            <Loader />
          ) : (
            <Formik
              initialValues={{
                name: '',
                deviceTypeId: '',
                // @ts-ignore severity is required but initially empty
                severity: '',
                operator: ANOMALY_OPERATOR.EQUAL,
                metric: '',
                thresholdValue: '',
                triggerPeriod: '',
                triggerPeriodType: ALERT_TRIGGER_PERIOD_TYPE.OCCURRENCES,
                deviceIds: [],
              }}
              onSubmit={async values => {
                setLoading(true);
                setError(null);
                try {
                  const dataToSend = {
                    name: values.name,
                    anomaly: {
                      metric: values.metric,
                      operator:
                        values.metric === 'inactive'
                          ? ANOMALY_OPERATOR.SUPERIOROREQUAL
                          : values.operator,
                      thresholdValue:
                        values.metric === 'inactive'
                          ? 0
                          : Number(values.thresholdValue),
                    },
                    triggerPeriod: Number(values.triggerPeriod),
                    triggerPeriodType:
                      values.metric === 'inactive'
                        ? ALERT_TRIGGER_PERIOD_TYPE.MINUTES
                        : values.triggerPeriodType,
                    severity: values.severity,
                    deviceIds: values.deviceIds,
                    watchers: watchers
                      .filter(w => w.value) // remove empty watchers
                      .map(w => ({
                        [w.value.includes('@') ? 'email' : 'phone']: w.value,
                      })),
                  };
                  await post(dataToSend);
                  redirectWithSuccess('/alerts', 'alert.add.form.success');
                } catch (e) {
                  setError(t('alert.add.form.error'));
                } finally {
                  setLoading(false);
                }
              }}
              validate={alertAddValidation}
            >
              {({ values, setFieldValue, errors }) => (
                <Form
                  {...values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  watchers={watchers}
                  setWatchers={setWatchers}
                  loading={loading}
                  error={error}
                  deviceTypes={deviceTypes}
                  devices={devices}
                />
              )}
            </Formik>
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}

export default AlertAdd;
