import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { Icon } from 'ui/icons';
import { useMediaType } from 'technical/media/hooks';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { SeverityBubble } from 'ui/severity-bubble';

interface IProps {
  rowElements: RowElement[];
  severity?: ALERT_SEVERITY;
  className?: string;
  mobileColumns?: number[];
  onClick?: () => void;
}

export type RowElement = {
  icon?: string;
  text?: string;
  subText?: string;
  flex?: number;
  label?: string;
};

const Row: React.FC<IProps> = ({
  rowElements = [],
  severity,
  className,
  mobileColumns,
  onClick,
}) => {
  const { isMobile } = useMediaType();

  return (
    <div className={classnames(styles.row, className)} onClick={onClick}>
      {severity ? (
        <SeverityBubble severity={severity} className={styles.bubble} />
      ) : null}
      {rowElements.map((rowElement, index) => {
        if (
          (mobileColumns && isMobile && mobileColumns.includes(index)) ||
          !isMobile
        ) {
          return (
            <div
              key={index}
              className={styles.rowElement}
              style={{
                flex: rowElement.flex ? rowElement.flex : 'auto',
              }}
              title={rowElement.label ? rowElement.label : ''}
            >
              <div className={styles.iconAndTextContainer}>
                {rowElement.icon && (
                  <Icon width={18} height={18}>
                    {rowElement.icon}
                  </Icon>
                )}
                <div>
                  <div className={styles.textRow}>{rowElement.text}</div>
                  <div className={styles.subTextRow}>{rowElement.subText}</div>
                </div>
              </div>
            </div>
          );
        }

        return null;
      })}
      <div className={styles.rowElement}>
        <Icon width={28} height={16}>
          chevron-right
        </Icon>
      </div>
    </div>
  );
};

export { Row };
