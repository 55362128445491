import validate from 'validate.js';
import { DeviceAddValues } from '../form';
import { REQUIRED, NUMBER } from '../../../../../technical/validation/rules';
import { i18n } from '../../../../../bootstrap/i18n';

interface Constraints {
  deviceTypeId: {};
  device: {};
  description: {};
  siteId: {};
  latitude?: {};
  longitude?: {};
  dashboardId?: {};
}

export const deviceAddValidation = (
  values: DeviceAddValues,
  modifyId?: string,
) => {
  const constraints: Constraints = {
    deviceTypeId: REQUIRED(i18n.t('device.add.form.deviceTypeId.required')),
    device: REQUIRED(i18n.t('device.add.form.device.error')),
    description: REQUIRED(i18n.t('device.add.form.description.required')),
    siteId: REQUIRED(i18n.t('device.add.form.siteId.required')),
  };

  if (values.latitude !== '' || values.longitude !== '') {
    constraints.latitude = {
      ...REQUIRED(i18n.t('device.add.form.latitude.required')),
      ...NUMBER(i18n.t('device.add.form.latitude.error')),
    };
    constraints.longitude = {
      ...REQUIRED(i18n.t('device.add.form.longitude.required')),
      ...NUMBER(i18n.t('device.add.form.longitude.error')),
    };
  }

  if (modifyId) {
    constraints.dashboardId = REQUIRED(
      i18n.t('device.add.form.dashboardId.required'),
    );
  }

  return validate(values, constraints);
};
