import { Image } from '../../media/image';
import * as React from 'react';
import img from './grolleau.png';
import styles from '../index.module.scss';

export const GrolleauLogo = (props: { width?: number }) => {
  let widthOption = {};
  if (props.width) {
    widthOption = { width: props.width };
  }

  return (
    <Image src={img} className={styles.logo} style={widthOption} {...props} />
  );
};
