import {
  ALERT_TRIGGER_PERIOD_TYPE,
  ANOMALY_OPERATOR,
} from 'common-active-invest-supervision/dist/src/business/alert/types';
import { IAlertV1Response } from 'common-active-invest-supervision/dist/src/business/alert/api/v1';
import { i18n } from 'bootstrap/i18n';

const operatorFormat = {
  [ANOMALY_OPERATOR.EQUAL]: '=',
  [ANOMALY_OPERATOR.INFERIOROREQUAL]: '<=',
  [ANOMALY_OPERATOR.SUPERIOROREQUAL]: '>=',
  [ANOMALY_OPERATOR.INFERIOR]: '<',
  [ANOMALY_OPERATOR.SUPERIOR]: '>',
};

export function formatAnomaly(alert: IAlertV1Response) {
  return `${alert.anomaly.metric} ${operatorFormat[alert.anomaly.operator]} ${
    alert.anomaly.thresholdValue
  }`;
}

export function formatAlertMessage(alert: IAlertV1Response, t: i18n.TFunction) {
  if (alert.anomaly.metric === 'inactive') {
    return t('alert.inactiveFormat', { count: alert.triggerPeriod });
  }

  return alert.triggerPeriodType === ALERT_TRIGGER_PERIOD_TYPE.MINUTES
    ? t('alert.minutesFormat', {
        anomaly: formatAnomaly(alert),
        minutes: alert.triggerPeriod,
      })
    : t('alert.occurrencesFormat', {
        anomaly: formatAnomaly(alert),
        occurrences: alert.triggerPeriod,
      });
}

export function formatAlertMessageView(
  alert: IAlertV1Response,
  t: i18n.TFunction,
) {
  if (alert.anomaly.metric === 'inactive') {
    return t('alert.view.mainInfo_inactive', {
      count: alert.triggerPeriod,
    });
  }

  return alert.triggerPeriodType === ALERT_TRIGGER_PERIOD_TYPE.MINUTES
    ? t('alert.view.mainInfo_minutes', {
        anomaly: formatAnomaly(alert),
        count: alert.triggerPeriod,
      })
    : t('alert.view.mainInfo_occurrences', {
        anomaly: formatAnomaly(alert),
        count: alert.triggerPeriod,
      });
}
