import React from 'react';
import { Button } from '../button';
import { TrashCan } from 'ui/icons/resources/trash-can';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export function DeleteButton({ children, ...rest }: Props) {
  return (
    <Button colorRole="secondary" colorMode="stroke" Icon={TrashCan} {...rest}>
      {children}
    </Button>
  );
}
