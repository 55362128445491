import { getDeviceType } from 'business/device-type/services/api';
import classNames from 'classnames';
import {
  ALERT_SEVERITY,
  ALERT_TRIGGER_PERIOD_TYPE,
  ANOMALY_OPERATOR,
} from 'common-active-invest-supervision/dist/src/business/alert/types';
import { IDeviceV1ResponseLight } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { Field, Form as FormikForm, FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/button/button';
import { StandaloneInputCheckbox } from 'ui/form/inputCheckbox/inputCheckbox';
import { InputText, StandaloneInputText } from 'ui/form/inputText';
import { Select } from 'ui/form/select';
import { Icon } from 'ui/icons';
import { Separator } from 'ui/layout/separator';
import { Loader } from 'ui/loader';
import { Watcher } from '.';
import { AlertValues } from '../types';
import styles from './form.module.scss';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  deviceTypeId: string;
  deviceIds: string[];
  watchers: Watcher[];
  setWatchers: (watchers: Watcher[]) => void;
  loading: boolean;
  error: string | null;
  deviceTypes: { id: string; name: string }[];
  devices: IDeviceV1ResponseLight[];
  modifyId?: string;
  metric: string;
  triggerPeriod: number | string;
  errors: FormikErrors<AlertValues>;
}

function Form({
  setFieldValue,
  deviceTypeId,
  deviceIds,
  watchers,
  setWatchers,
  loading,
  error,
  deviceTypes,
  devices,
  modifyId,
  metric,
}: Props) {
  const { t } = useTranslation();
  const filteredDevices = devices.filter(d => d.deviceType.id === deviceTypeId);
  const [metrics, setMetrics] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getMetrics() {
      try {
        setIsFetching(true);
        const data = await getDeviceType(deviceTypeId);
        if (data) {
          setMetrics(data.metrics);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    if (deviceTypeId) {
      getMetrics();
    }
  }, [deviceTypeId, setFieldValue]);

  return isFetching ? (
    <Loader />
  ) : (
    <FormikForm autoComplete="off">
      <Field
        name="name"
        placeholder={t('alert.add.form.name.placeholder')}
        component={InputText}
        className={styles.inputText}
      />
      <div className={styles.multipleOnLine}>
        <Field
          className={classNames(styles.inputText, styles.marginRight)}
          name="deviceTypeId"
          placeholder={t('alert.add.form.deviceTypeId.placeholder')}
          component={Select}
          options={deviceTypes.map(d => ({
            value: d.id,
            label: d.name,
          }))}
          onChange={(option: { label: string; value: string }) => {
            setFieldValue('deviceTypeId', option.value);
            setFieldValue('metric', '');
            setFieldValue('deviceIds', []);
          }}
        />
        <Field
          className={styles.inputText}
          name="severity"
          placeholder={t('alert.add.form.severity.placeholder')}
          component={Select}
          options={[
            {
              value: ALERT_SEVERITY.MINOR,
              label: t('alert.severity.MINOR'),
            },
            {
              value: ALERT_SEVERITY.MAJOR,
              label: t('alert.severity.MAJOR'),
            },
            {
              value: ALERT_SEVERITY.CRITICAL,
              label: t('alert.severity.CRITICAL'),
            },
          ]}
        />
      </div>

      <div className={classNames(styles.inputText, styles.bolder)}>
        {t('alert.add.form.if')}
      </div>
      <div className={styles.multipleOnLine}>
        <Field
          className={styles.inputText}
          name="metric"
          placeholder={t('alert.add.form.metric.placeholder')}
          component={Select}
          options={metrics
            .map(m => ({
              value: m,
              label: m,
            }))
            .concat({
              value: 'inactive',
              label: t('alert.add.form.metric.inactive'),
            })}
        />
        {metric !== 'inactive' && (
          <div className={styles.multipleOnLine}>
            <Field
              className={classNames(
                styles.inputText,
                styles.marginHorizontalNoMobile,
              )}
              name="operator"
              component={Select}
              options={[
                { value: ANOMALY_OPERATOR.EQUAL, label: '=' },
                { value: ANOMALY_OPERATOR.INFERIOROREQUAL, label: '<=' },
                { value: ANOMALY_OPERATOR.SUPERIOROREQUAL, label: '>=' },
                { value: ANOMALY_OPERATOR.INFERIOR, label: '<' },
                { value: ANOMALY_OPERATOR.SUPERIOR, label: '>' },
              ]}
            />
            <Field
              name="thresholdValue"
              type="number"
              step="any"
              placeholder={t('alert.add.form.thresholdValue.placeholder')}
              component={InputText}
              className={styles.inputText}
            />
          </div>
        )}
      </div>

      <div className={classNames(styles.inputText, styles.bolder)}>
        {t('alert.add.form.during')}
      </div>
      <div className={styles.multipleOnLineNoMobile}>
        <Field
          name="triggerPeriod"
          type="number"
          step="any"
          placeholder={t('alert.add.form.triggerPeriod.placeholder')}
          component={InputText}
          className={styles.inputText}
        />

        {metric === 'inactive' ? (
          <div className={classNames(styles.inputText, styles.marginLeft)}>
            {t('alert.add.form.triggerPeriod.inactive.unit')}
          </div>
        ) : (
          <Field
            className={classNames(
              styles.inputText,
              styles.marginHorizontalNoMobile,
            )}
            name="triggerPeriodType"
            component={Select}
            options={
              metric === 'inactive'
                ? [
                    {
                      value: ALERT_TRIGGER_PERIOD_TYPE.MINUTES,
                      label: t('alert.add.form.triggerPeriod.inactive.unit'),
                    },
                  ]
                : [
                    {
                      value: ALERT_TRIGGER_PERIOD_TYPE.OCCURRENCES,
                      label: t('alert.add.form.triggerPeriod.occurrences.unit'),
                    },
                    {
                      value: ALERT_TRIGGER_PERIOD_TYPE.MINUTES,
                      label: t('alert.add.form.triggerPeriod.inactive.unit'),
                    },
                  ]
            }
          />
        )}
      </div>

      {filteredDevices.length > 0 ? (
        <>
          <Separator className={styles.separator} />
          <p>{t('alert.add.form.deviceIds.label')}</p>
          <StandaloneInputCheckbox
            label={<strong>{t('alert.add.form.deviceIds.toggleAll')}</strong>}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFieldValue(
                'deviceIds',
                e.currentTarget.checked ? filteredDevices.map(d => d.id) : [],
              )
            }
          />

          {filteredDevices.map(device => (
            <StandaloneInputCheckbox
              key={device.id}
              label={device.description}
              checked={deviceIds.includes(device.id)}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if (e.currentTarget.checked) {
                  setFieldValue('deviceIds', [...deviceIds, device.id]);
                } else {
                  const index = deviceIds.indexOf(device.id);
                  setFieldValue('deviceIds', [
                    ...deviceIds.slice(0, index),
                    ...deviceIds.slice(index + 1),
                  ]);
                }
              }}
              className={styles.checkbox}
            />
          ))}
        </>
      ) : null}
      <Separator className={styles.separator} />
      <div
        className={classNames(styles.multipleOnLineNoMobile, styles.marginTop)}
      >
        <Icon width={16} height={16}>
          settings
        </Icon>
        <span className={styles.iconText}>
          {t('alert.maintainersAutomaticAlerts')}
        </span>
      </div>

      <div className={styles.multipleOnLineNoMobile}>
        <div className={styles.inputText}>
          {t('alert.add.form.watchers.label')}
        </div>
        <button
          type="button"
          className={styles.addButton}
          onClick={() =>
            setWatchers([...watchers, { isActivated: false, value: '' }])
          }
        >
          +
        </button>
      </div>
      {watchers.map((watcher, index) => (
        <div
          key={index}
          className={classNames(styles.multipleOnLineNoMobile, styles.spacer)}
        >
          {modifyId ? (
            <StandaloneInputCheckbox
              checked={watcher.isActivated}
              onChange={e => {
                const newWatchers = [...watchers];
                newWatchers[index].isActivated = e.currentTarget.checked;
                setWatchers(newWatchers);
              }}
            />
          ) : null}

          <StandaloneInputText
            placeholder={t('alert.add.form.watchers.placeholder')}
            value={watcher.value}
            onChange={e => {
              const newWatchers = [...watchers];
              newWatchers[index].value = e.currentTarget.value;
              setWatchers(newWatchers);
            }}
          />
          <button
            type="button"
            className={styles.addButton}
            style={{ marginTop: 0 }}
            onClick={() =>
              setWatchers([
                ...watchers.slice(0, index),
                ...watchers.slice(index + 1),
              ])
            }
          >
            &times;
          </button>
        </div>
      ))}
      <div className={styles.actions}>
        <Button disabled={loading} type="submit">
          {t(modifyId ? 'alert.modify.form.submit' : 'alert.add.form.submit')}
        </Button>
      </div>
      {error !== null && <div className={styles.errors}>{error}</div>}
    </FormikForm>
  );
}

export default Form;
