import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';
import { Icon } from './../icons';

export const SearchInput = ({
  className,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) => (
  <div className={classNames(styles.container, className)}>
    <span className={styles.iconContainer}>
      <Icon width={16} height={16}>
        mag
      </Icon>
    </span>
    <input type="search" className={styles.textInput} {...props} />
  </div>
);
