import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Icon } from 'ui/icons';
import { useMediaType } from 'technical/media/hooks';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { SeverityBubble } from 'ui/severity-bubble';

interface IProps {
  listRowElements: ListRowElement[];
  severity?: ALERT_SEVERITY;
  className?: string;
  mobileColumns?: number[];
  onClick?: () => void;
}

export type ListRowElement = {
  text?: string;
  type: string;
};

const ListRow: React.FC<IProps> = ({
  listRowElements = [],
  severity,
  className,
  mobileColumns,
  onClick,
}) => {
  const { isMobile } = useMediaType();

  return (
    <div className={classNames(styles.listRow, className)} onClick={onClick}>
      {listRowElements.map((listRowElement, index) => {
        if (
          (mobileColumns && isMobile && mobileColumns.includes(index)) ||
          !isMobile
        ) {
          return (
            <div
              key={index}
              className={styles.listRowElement}
              style={{
                flex: 'auto',
              }}
            >
              <Icon width={25} height={25}>
                {listRowElement.type}
              </Icon>
              <div className={styles.iconAndTextContainer}>
                <div>
                  <div className={styles.textListRow}>
                    {listRowElement.text}
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return null;
      })}
      <div className={styles.listRowElement}>
        <SeverityBubble severity={severity} className={styles.bubble} />
      </div>
    </div>
  );
};

export { ListRow };
