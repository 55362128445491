import React from 'react';
import styles from './index.module.scss';
import { Icon } from 'ui/icons';

interface IProps {
  setPage: (page: number) => void;
  page: number;
  totalPage: number;
}

const Paginator: React.FC<IProps> = ({ setPage, page, totalPage }) => {
  return (
    <div className={styles.paginator}>
      <div className={styles.spacer} />
      <div
        className={styles.arrowBox}
        onClick={() => setPage(Math.max(1, page - 1))}
      >
        <Icon width={24} height={24}>
          arrow-left
        </Icon>
      </div>
      {`${page}/${totalPage}`}
      <div
        className={styles.arrowBox}
        onClick={() => setPage(Math.min(totalPage, page + 1))}
      >
        <Icon width={24} height={24}>
          arrow-right
        </Icon>
      </div>
    </div>
  );
};

export { Paginator };
