import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props {
  className?: string;
}

export function Separator({ className }: Props) {
  return <div className={classNames(styles.separator, className)} />;
}
