import Login from 'business/user/pages/login';
import * as React from 'react';
import { Route, Router as ReactDomRouter } from 'react-router-dom';
import history from 'technical/history';
import { SwitchNotFoundHandler } from 'technical/router/switch';
import ForgotPassword from '../../business/user/pages/forgot-password';
import UserAdd from '../../business/user/pages/add';
import DeviceList from 'business/device/pages/list';
import DeviceView from 'business/device/pages/view';
import DeviceModify from 'business/device/pages/modify';
import DeviceTypeAdd from 'business/device-type/pages/add';
import CompanyAdd from 'business/company/pages/add';
import SiteAdd from 'business/site/pages/add';
import DeviceAdd from 'business/device/pages/add';
import ClientAdd from 'business/client/pages/add';
import UserList from 'business/user/pages/list';
import DeviceTypeList from 'business/device-type/pages/list';
import ClientList from 'business/client/pages/list';
import SiteList from 'business/site/pages/list';
import CompanyList from 'business/company/pages/list';
import CompanyView from 'business/company/pages/view';
import SiteView from 'business/site/pages/view';
import UserView from 'business/user/pages/view';
import DeviceTypeView from 'business/device-type/pages/view';
import AlertAdd from 'business/alert/pages/add';
import ClientView from 'business/client/pages/view';
import AlertList from 'business/alert/pages/list';
import SiteModify from 'business/site/pages/modify';
import AlarmList from 'business/alarm/pages/list';
import CompanyModify from 'business/company/pages/modify';
import UserModify from 'business/user/pages/modify';
import AlertView from 'business/alert/pages/view';
import AlertModify from 'business/alert/pages/modify';
import ClientModify from 'business/client/pages/modify';
import DeviceTypeModify from 'business/device-type/pages/modify';
import { Home } from 'business/user/pages/home';

export enum Routes {
  'Home' = '/',
  'HomeNotConnected' = '/login',
  'HomeConnected' = '/devices',

  // Not-connected routes
  'Login' = '/login',
  'ForgotPassword' = '/forgot-password',

  // Users
  'UserList' = '/users',
  'UserAdd' = '/user/create',
  'UserView' = '/user/view/:id',
  'UserModify' = '/user/modify/:id',

  // Device Type
  'DeviceTypeList' = '/device-types',
  'DeviceTypeAdd' = '/device-type/add',
  'DeviceTypeView' = '/device-type/view/:id',
  'DeviceTypeModify' = '/device-type/modify/:id',

  // Company
  'CompanyList' = '/companies',
  'CompanyAdd' = '/company/create',
  'CompanyView' = '/company/view/:id',
  'CompanyModify' = '/company/modify/:id',

  // Site
  'SiteList' = '/sites',
  'SiteAdd' = '/site/add',
  'SiteView' = '/site/view/:id',
  'SiteModify' = '/site/modify/:id',

  // Device
  'DeviceList' = '/devices',
  'DeviceAdd' = '/device/add',
  'DeviceView' = '/device/view/:id',
  'DeviceModify' = '/device/modify/:id',

  // Client
  'ClientList' = '/clients',
  'ClientAdd' = '/client/add',
  'ClientView' = '/client/view/:id',
  'ClientModify' = '/client/update/:id',

  // Alert
  'AlertList' = '/alerts',
  'AlertAdd' = '/alert/add',
  'AlertView' = '/alert/view/:id',
  'AlertModify' = '/alert/modify/:id',

  // Alarm
  'AlarmList' = '/alarms',
}

export const Router = () => {
  return (
    <ReactDomRouter history={history}>
      <SwitchNotFoundHandler>
        <Route path={Routes.Home} exact component={Home} />
        <Route path={Routes.Login} exact component={Login} />
        <Route path={Routes.ForgotPassword} exact component={ForgotPassword} />

        <Route path={Routes.UserAdd} exact component={UserAdd} />
        <Route path={Routes.UserList} exact component={UserList} />
        <Route path={Routes.UserView} exact component={UserView} />
        <Route path={Routes.UserModify} exact component={UserModify} />

        <Route path={Routes.DeviceList} exact component={DeviceList} />
        <Route path={Routes.DeviceAdd} exact component={DeviceAdd} />
        <Route path={Routes.DeviceView} exact component={DeviceView} />
        <Route path={Routes.DeviceModify} exact component={DeviceModify} />

        <Route path={Routes.CompanyList} exact component={CompanyList} />
        <Route path={Routes.CompanyAdd} exact component={CompanyAdd} />
        <Route path={Routes.CompanyView} exact component={CompanyView} />
        <Route path={Routes.CompanyModify} exact component={CompanyModify} />

        <Route path={Routes.DeviceTypeList} exact component={DeviceTypeList} />
        <Route path={Routes.DeviceTypeAdd} exact component={DeviceTypeAdd} />
        <Route path={Routes.DeviceTypeView} exact component={DeviceTypeView} />
        <Route
          path={Routes.DeviceTypeModify}
          exact
          component={DeviceTypeModify}
        />

        <Route path={Routes.SiteList} exact component={SiteList} />
        <Route path={Routes.SiteAdd} exact component={SiteAdd} />
        <Route path={Routes.SiteView} exact component={SiteView} />
        <Route path={Routes.SiteModify} exact component={SiteModify} />

        <Route path={Routes.ClientList} exact component={ClientList} />
        <Route path={Routes.ClientAdd} exact component={ClientAdd} />
        <Route path={Routes.ClientView} exact component={ClientView} />
        <Route path={Routes.ClientModify} exact component={ClientModify} />

        <Route path={Routes.AlertList} exact component={AlertList} />
        <Route path={Routes.AlertAdd} exact component={AlertAdd} />
        <Route path={Routes.AlertView} exact component={AlertView} />
        <Route path={Routes.AlertModify} exact component={AlertModify} />

        <Route path={Routes.AlarmList} exact component={AlarmList} />
      </SwitchNotFoundHandler>
    </ReactDomRouter>
  );
};
