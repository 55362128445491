import React from 'react';
import { Marker } from '@react-google-maps/api';

interface IProps {
  lat: number;
  lng: number;
}

export function SimpleMarker({ lat, lng }: IProps) {
  const markerSvg = `<svg
    width="36"
    height="36"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="5"
      y="2"
      width="14"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9ZM9.5 9C9.5 10.38 10.62 11.5 12 11.5C13.38 11.5 14.5 10.38 14.5 9C14.5 7.62 13.38 6.5 12 6.5C10.62 6.5 9.5 7.62 9.5 9Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H24V24H0V0Z"
        fill="#77777a"
      />
    </g>
  </svg>`;

  return (
    <Marker
      position={{
        lat,
        lng,
      }}
      icon={{
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(markerSvg)}`,
      }}
    ></Marker>
  );
}
