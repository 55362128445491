import React from 'react';
import styles from './index.module.scss';
import { Icon } from 'ui/icons';
import classNames from 'classnames';

const DeviceStatus: React.FC<{
  count: number;
  total: number;
  icon: string;
}> = ({ count, total, icon }) => {
  if (!total) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.marker,
          count > 0 ? styles.alerts : styles.noAlerts,
        )}
      />
      <div className={styles.icon}>
        <Icon width={25} height={25}>
          {icon}
        </Icon>
      </div>
      <div className={styles.text}>
        <span>{count}</span>
        <span className={styles.slash}>/</span>
        <span>{total}</span>
      </div>
    </div>
  );
};

export { DeviceStatus };
