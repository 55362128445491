import React, { ReactNode, useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from 'ui/styles/view.module.scss';
import { SectionTitle } from 'ui/typo/section-title';

import { useParams, useHistory } from 'react-router';
import { getCompany, deleteCompany } from 'business/company/services/api';
import { ICompanyDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/company/api/v1';
import { Icon } from 'ui/icons';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';

const CompanyView: React.FC<ReactNode> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [company, setCompany] = useState<ICompanyDetailedV1Response>();
  const { user } = useUserContext();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getCompany(id);
          setCompany(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  const buttons = (
    <>
      {user &&
      [UserRole.COMPANY_MANAGER, UserRole.GROUP_MANAGER].includes(user.role) ? (
        <ModifyButton
          className={styles.modifyButton}
          onClick={() => history.push(`/company/modify/${id}`)}
        >
          {t('common.modify')}
        </ModifyButton>
      ) : null}

      {user && user.role === UserRole.GROUP_MANAGER ? (
        <DeleteButton
          className={styles.modifyButton}
          onClick={async () => {
            try {
              if (window.confirm(t('company.delete.confirm'))) {
                await deleteCompany(String(id));
                redirectWithSuccess('/companies', 'company.delete.success');
              }
            } catch (e) {
              redirectWithError('/companies', 'company.delete.error');
            }
          }}
        >
          {t('common.delete')}
        </DeleteButton>
      ) : null}
    </>
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {company ? (
            <>
              <SectionTitle text={company.name} />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>

              <div className={styles.panelContainer}>
                <div>
                  <div
                    className={styles.rowIconText}
                    title={t('company.view.defaultDeviceCategory')}
                  >
                    <Icon width={16} height={16}>
                      help
                    </Icon>
                    <div className={styles.details}>
                      {t(
                        `common.defaultDeviceCategory.${company.defaultDeviceCategory}`,
                      )}
                    </div>
                  </div>

                  {company.email && (
                    <div
                      className={styles.rowIconText}
                      title={t('common.email')}
                    >
                      <Icon width={16} height={16}>
                        email
                      </Icon>
                      <div className={styles.details}>{company.email}</div>
                    </div>
                  )}

                  {company.phone && (
                    <div
                      className={styles.rowIconText}
                      title={t('common.phone')}
                    >
                      <Icon width={16} height={16}>
                        phone
                      </Icon>
                      <div className={styles.details}>{company.phone}</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default CompanyView;
