import { getAlert, updateAlert } from 'business/alert/services/api';
import { fetchDevicesLight } from 'business/device/services/api';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { IAlertV1Response } from 'common-active-invest-supervision/dist/src/business/alert/api/v1';
import { IDeviceV1ResponseLight } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { Formik } from 'formik';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router';
import { redirectWithSuccess } from 'technical/flash-message';
import { Loader } from 'ui/loader';
import { SectionTitle } from 'ui/typo/section-title';
import styles from '../../../../ui/styles/add-form.module.scss';
import BasePage from '../../../base-page';
import { Watcher } from '../add';
import Form from '../add/form';
import { alertAddValidation } from '../add/service';
import { isAllowed } from '../list';

export default function AlertModify() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();
  const [watchers, setWatchers] = useState<Watcher[]>([
    { isActivated: false, value: '' },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [alert, setAlert] = useState<IAlertV1Response | null>(null);
  const [devices, setDevices] = useState<IDeviceV1ResponseLight[]>([]);
  const deviceTypes = uniqBy(devices.map(d => d.deviceType), 'id');
  const [deviceTypeId, setDeviceTypeId] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const [resultDevices, resultAlert] = await Promise.all([
          fetchDevicesLight(),
          getAlert(id),
        ]);

        if (resultDevices && resultAlert) {
          setAlert(resultAlert);
          setDevices(resultDevices.items);
          setWatchers(
            resultAlert.watchers.map(w => ({
              isActivated: w.isActivated,
              value: String(w.email || w.phone),
            })),
          );

          // back does not send us the initial deviceTypeId so we have to compute it
          const deviceMatchingFirstAlertDevice = resultDevices.items.find(
            d => d.id === resultAlert.devices[0].id,
          );
          if (deviceMatchingFirstAlertDevice) {
            setDeviceTypeId(deviceMatchingFirstAlertDevice.deviceType.id);
          }
        }
      }
    }

    fetchData();
  }, [id]);

  if (user && !isAllowed(user)) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>
          <SectionTitle text={t('alert.modify.title')} />
          {alert && deviceTypeId ? (
            <Formik
              initialValues={{
                deviceTypeId,
                name: alert.name,
                severity: alert.severity,
                operator: alert.anomaly.operator,
                metric: alert.anomaly.metric,
                thresholdValue: alert.anomaly.thresholdValue,
                triggerPeriod: alert.triggerPeriod,
                triggerPeriodType: alert.triggerPeriodType,
                deviceIds: alert.devices.map(d => d.id),
              }}
              onSubmit={async values => {
                setLoading(true);
                setError(null);
                try {
                  const dataToSend = {
                    name: values.name,
                    anomaly: {
                      metric: values.metric,
                      operator: values.operator,
                      thresholdValue: Number(values.thresholdValue),
                    },
                    triggerPeriod: Number(values.triggerPeriod),
                    triggerPeriodType: values.triggerPeriodType,
                    severity: values.severity,
                    deviceIds: values.deviceIds,
                    watchers: watchers
                      .filter(w => w.value) // remove empty watchers
                      .map(w => ({
                        isActivated: w.isActivated,
                        [w.value.includes('@') ? 'email' : 'phone']: w.value,
                      })),
                  };
                  await updateAlert(String(id), dataToSend);
                  redirectWithSuccess('/alerts', 'alert.modify.form.success');
                } catch (e) {
                  setError(t('alert.modify.form.error'));
                } finally {
                  setLoading(false);
                }
              }}
              validate={alertAddValidation}
            >
              {({ values, setFieldValue, errors }) => (
                <Form
                  modifyId={id}
                  {...values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  watchers={watchers}
                  setWatchers={setWatchers}
                  loading={loading}
                  error={error}
                  deviceTypes={deviceTypes}
                  devices={devices}
                />
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}
