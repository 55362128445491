import React, { useState, useEffect } from 'react';
import { ConnectedContainer } from '../../containers/connectedOrNotContainers';
import BasePage from '../../../base-page';
import styles from './index.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import { useTranslation } from 'react-i18next';
import { IUserV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { listUsers } from 'business/user/services/api';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { StandaloneSelect } from 'ui/form/select';
import { Option } from 'react-select/src/filters';
import { Icon } from 'ui/icons';

import { useMediaType } from 'technical/media/hooks';
import { Modal } from 'ui/modal';
import { useHistory } from 'react-router';
import { Routes } from 'bootstrap/router';
import { Paginator } from 'ui/paginator';
import { FlashMessage } from 'ui/flash-message';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { useUserContext } from 'business/user/states';
import { Button } from 'ui/button/button';
import { AddButton } from 'ui/button/add-button';
import { SearchInput } from '../../../../ui/search-input';
import { Separator } from '../../../../ui/layout/separator';

const UserList: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<IUserV1Response[]>([]);
  const [userRole, setUserRole] = useState<UserRole | ''>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const history = useHistory();
  const { isMobile } = useMediaType();
  const { user } = useUserContext();

  const searchTextInput = (
    <SearchInput
      defaultValue={searchValue}
      onChange={e => {
        setSearchValue(e.currentTarget.value);
        setPage(1);
      }}
      placeholder={t(`user.list.searchPlaceholder`)}
    />
  );

  const filterByRole = (style: string) => (
    <StandaloneSelect
      className={style}
      placeholder={t(`user.list.role.placeholder`)}
      onChange={option => {
        const stringValue = option && (option as Option).value;
        setUserRole(stringValue as UserRole);
        setPage(1);
      }}
      options={[
        {
          value: '',
          label: t(`user.add.form.role.allRoles`),
        },
        ...Object.values(UserRole).map((role: string) => ({
          value: role,
          label: t(`user.add.form.role.options.${role}`),
        })),
      ]}
    />
  );

  const buttons =
    user &&
    [
      UserRole.GROUP_MANAGER,
      UserRole.COMPANY_MANAGER,
      UserRole.CLIENT_MANAGER,
    ].includes(user.role) ? (
      <AddButton onClick={() => history.push(Routes.UserAdd)}>
        {t('user.list.add')}
      </AddButton>
    ) : null;

  useEffect(() => {
    async function getUsers() {
      try {
        setIsFetching(true);
        const { items, metadata } = await listUsers({
          page,
          search: searchValue,
          role: userRole ? userRole : undefined,
          limit: 5,
        });
        setUsers(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getUsers();
  }, [userRole, searchValue, page]);

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <SectionTitle text={t('user.list.title')} />
          <div className={styles.actionContainer}>
            <div className={styles.spacer} />

            {isMobile && (
              <div
                onClick={() => {
                  setSearchModalOpen(true);
                }}
              >
                <Icon width={32} height={32}>
                  filter
                </Icon>
              </div>
            )}
          </div>
          {!isMobile && (
            <div>
              <div className={styles.searchRow}>
                <div>{t('user.list.role.filterBy')}</div>
                {filterByRole(styles.select)}
                <div className={styles.spacer} />
                <div className={styles.searchText}>{searchTextInput}</div>
                <div>{buttons}</div>
              </div>
              <Separator className={styles.separator} />
            </div>
          )}

          <div style={{ marginBottom: '1em' }}>
            <FlashMessage />
          </div>

          <Loader isLoading={isFetching} />

          <NoResults isVisible={!isFetching && !users.length} />

          {users.map((user, index) => (
            <Row
              key={index}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: `${user.firstName} ${user.lastName}`,
                  subText: t(`user.add.form.role.options.${user.role}`),
                  flex: 2,
                },
                {
                  icon: user.client ? 'chart-pie' : 'null',
                  text: user.client && user.client.name,
                  flex: 2,
                },
                {
                  icon: 'phone',
                  subText: `${
                    user.email ? `${user.email}\n` : ''
                  }${user.phone || ''}`,
                  flex: 4,
                },
              ]}
              onClick={() => history.push(`/user/view/${user.id}`)}
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} page={page} totalPage={totalPage} />
          )}
        </div>
        <Modal
          isOpen={searchModalOpen}
          onRequestClose={() => setSearchModalOpen(false)}
        >
          <div className={styles.filterBy}>{t('user.list.role.filterBy')}</div>

          {filterByRole(styles.selectModal)}

          <div>{searchTextInput}</div>

          <div className={styles.buttonModal}>
            <Button onClick={() => setSearchModalOpen(false)}>
              {t('user.list.search')}
            </Button>
          </div>
        </Modal>
      </BasePage>
    </ConnectedContainer>
  );
};

export default UserList;
