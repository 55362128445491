import moment from 'moment';
import 'moment/locale/fr';

/**
 * Format sample: 14 octobre 2019 17:12
 * @param date
 */
export const formatDateLLL = (date: Date | string) => {
  return moment(date).format('LLL');
};

/**
 * Format sample: 18/10/2019
 * @param date
 */
export const formatDateL = (date: Date | string) => {
  return moment(date).format('L');
};

/**
 * Format sample: 08:53
 * @param date
 */
export const formatDateLT = (date: Date | string) => {
  return moment(date).format('LT');
};

export const superiorTime = (date: Date) => {
  const now = moment(Date());
  const duration = moment.duration(now.diff(date));

  if (duration.asHours() > 0) {
    return `${duration.asHours().toFixed(0)}h`;
  }

  return `${duration.asMinutes().toFixed(0)}m`;
};
