import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Redirect, useHistory, useParams } from 'react-router';
import FormDeviceTypeAdd from '../add/form';
import { getDeviceType } from 'business/device-type/services/api';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { Loader } from 'ui/loader';

const DeviceTypeModify: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();
  const { id } = useParams();
  const [deviceType, setDeviceType] = useState<IDeviceTypeV1Response>();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getDeviceType(id);
          setDeviceType(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  if (user && ![UserRole.COMPANY_MANAGER].includes(user.role)) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {deviceType ? (
            <FormDeviceTypeAdd
              initialData={{
                name: deviceType.name,
                dashboardUrl: deviceType.dashboardUrl,
                network: deviceType.network,
              }}
              modifyId={id}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceTypeModify;
