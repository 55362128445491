import React from 'react';
import { Button } from '../button';
import { TodoListPen } from 'ui/icons/resources/todo-list-pen';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export function ModifyButton({ children, ...rest }: Props) {
  return (
    <Button colorMode="stroke" Icon={TodoListPen} {...rest}>
      {children}
    </Button>
  );
}
