import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

export interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  colorRole?: 'primary' | 'secondary' | 'danger';
  colorMode?: 'fill' | 'stroke';
  Icon?: ({ color }: { color: string }) => JSX.Element;
}

const strokeColorByRole = {
  danger: '#ff0000',
  primary: '#0c2396',
  secondary: '#77777a',
};

export function Button({
  disabled = false,
  className,
  type = 'button',
  children,
  colorRole = 'primary',
  colorMode = 'fill',
  Icon,
  ...props
}: IProps) {
  return (
    <button
      type={type}
      {...props}
      className={classnames(
        styles.button,
        styles[colorMode],
        styles[colorRole],
        className,
      )}
      disabled={disabled}
    >
      {Icon ? (
        <span className={styles.iconContainer}>
          <Icon
            color={
              colorMode === 'stroke' ? strokeColorByRole[colorRole] : 'white'
            }
          />
        </span>
      ) : null}
      <span className={styles.text}>{children}</span>
    </button>
  );
}
