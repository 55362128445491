import React, { useState, useEffect } from 'react';
import BasePage from '../../../base-page';
import styles from 'ui/styles/list.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import { useTranslation } from 'react-i18next';

import { useMediaType } from 'technical/media/hooks';
import { Modal } from 'ui/modal';
import { Routes } from 'bootstrap/router';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { ISiteDetailV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';

import { listSites } from 'business/site/services/api';
import { Paginator } from 'ui/paginator';
import { FlashMessage } from 'ui/flash-message';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Redirect, useHistory } from 'react-router';
import { useUserContext } from 'business/user/states';
import { formatDateLLL } from 'technical/date';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { Button } from 'ui/button/button';
import { AddButton } from 'ui/button/add-button';

const SiteList: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const [sites, setSites] = useState<ISiteDetailV1Response[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const { isMobile } = useMediaType();
  const { user } = useUserContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getSites() {
      try {
        setIsFetching(true);
        const { items, metadata } = await listSites({ page });
        setSites(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getSites();
  }, [page]);

  const unauthorizedUserGroups = [UserRole.MAINTAINER];
  if (user && unauthorizedUserGroups.includes(user.role)) {
    return <Redirect to="/" />;
  }

  const authorizedToAdd = [UserRole.COMPANY_MANAGER];
  const buttons =
    user && authorizedToAdd.includes(user.role) ? (
      <>
        <AddButton onClick={() => history.push(Routes.SiteAdd)}>
          {t('site.list.add')}
        </AddButton>
      </>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <SectionTitle text={t('site.list.title')} />
          <div className={styles.actionContainer}>{!isMobile && buttons}</div>

          <div style={{ marginBottom: '1em' }}>
            <FlashMessage />
          </div>

          <Loader isLoading={isFetching} />

          <NoResults isVisible={!isFetching && !sites.length} />

          {sites.map(site => (
            <Row
              key={site.id}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: site.name,
                  subText: site.client.name,
                  flex: 2,
                },
                {
                  subText: `${t('site.list.created')} ${formatDateLLL(
                    site.creationTime,
                  )} \n${t('site.list.updated')} ${formatDateLLL(
                    site.updateTime,
                  )}`,
                  flex: 2,
                },
              ]}
              onClick={() => history.push(`/site/view/${site.id}`)}
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} page={page} totalPage={totalPage} />
          )}
        </div>
        <Modal
          isOpen={searchModalOpen}
          onRequestClose={() => setSearchModalOpen(false)}
        >
          <Button
            className={styles.buttonModal}
            onClick={() => history.push(Routes.SiteAdd)}
          >
            {t('site.list.add')}
          </Button>
        </Modal>
      </BasePage>
    </ConnectedContainer>
  );
};

export default SiteList;
