import React from 'react';
import { Routes } from 'bootstrap/router';
import { Redirect } from 'react-router';
import { useUserContext } from 'business/user/states';

export function Home() {
  const { isConnected, isLoading } = useUserContext();

  if (isLoading) {
    return null;
  }

  return (
    <Redirect
      to={isConnected ? Routes.HomeConnected : Routes.HomeNotConnected}
    />
  );
}
