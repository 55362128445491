import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styles from './index.module.scss';
import classNames from 'classnames';

interface LocationState {
  flashMessageSuccess?: string;
  flashMessageError?: string;
}

export function FlashMessage() {
  const { t } = useTranslation();
  const { state } = useLocation<undefined | LocationState>();

  if (state && state.flashMessageError) {
    return (
      <div className={classNames(styles.message, styles.error)}>
        {t(state.flashMessageError)}
      </div>
    );
  }

  if (state && state.flashMessageSuccess) {
    return (
      <div className={classNames(styles.message, styles.success)}>
        {t(state.flashMessageSuccess)}
      </div>
    );
  }

  return null;
}
