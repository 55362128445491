import { MarkerClusterer } from '@react-google-maps/api';
import { computeDeviceLocation } from 'business/device/services';
import { ALARM_STATUS } from 'common-active-invest-supervision/dist/src/business/alarm/types';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { COMPANY_DEFAULT_CATEGORY_DEVICE } from 'common-active-invest-supervision/dist/src/business/company/types';
import { IDeviceV1ResponseLight } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FRANCE_REGION } from 'technical/google-maps/constants';
import { DeviceMarker } from 'ui/google-maps/markers/device';
import { ProjectMap } from 'ui/google-maps/project-map';
import { NoResults } from 'ui/no-results';
import { ListRow } from '../../../../../ui/list';
import styles from './index.module.scss';

interface Props {
  devices: IDeviceV1ResponseLight[];
  filtersSearch: string;
}

function countAlerts(device: IDeviceV1ResponseLight) {
  return device.alarms.filter(
    alarm =>
      ![ALARM_STATUS.CLOSED, ALARM_STATUS.OPEN_NOT_NOTIFY].includes(
        alarm.status,
      ),
  ).length;
}

function maxSeverities(severity1: ALERT_SEVERITY, severity2: ALERT_SEVERITY) {
  if (severity1 === ALERT_SEVERITY.MINOR) {
    return severity2;
  }
  if (
    severity1 === ALERT_SEVERITY.MAJOR &&
    ALERT_SEVERITY.CRITICAL === severity2
  ) {
    return severity2;
  }

  return severity1;
}

function getMaxSeverity(device: IDeviceV1ResponseLight): ALERT_SEVERITY | null {
  return device.alarms.reduce(
    (maxSeverity, alarm) => {
      if (
        ![ALARM_STATUS.CLOSED, ALARM_STATUS.OPEN_NOT_NOTIFY].includes(
          alarm.status,
        )
      ) {
        if (maxSeverity === null) {
          return alarm.alert.severity;
        }

        // @ts-ignore Not null by condition
        return maxSeverities(maxSeverity, alarm.alert.severity);
      }

      return maxSeverity;
    },
    null as ALERT_SEVERITY | null,
  );
}

function DevicesMap({ devices, filtersSearch }: Props) {
  const [infoWindowIndex, setInfoWindowIndex] = useState<string | null>(null);
  const [center, setCenter] = useState<{
    lat: number;
    lng: number;
  }>(FRANCE_REGION.center);
  const history = useHistory();
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [devicesInBounds, setDevicesInBounds] = useState<
    IDeviceV1ResponseLight[]
  >([]);

  const computeDevicesInBounds = useCallback(() => {
    if (mapInstance) {
      const bounds = mapInstance.getBounds();
      if (bounds) {
        setDevicesInBounds(
          devices.filter(device =>
            bounds.contains(computeDeviceLocation(device)),
          ),
        );
      }
    }
  }, [mapInstance, devices]);

  const typeMapping = {
    [COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY]: 'battery',
    [COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET]: 'cabinet',
    [COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT]: 'lamp',
  };

  // This effect fit bounds each time new devices are received
  useEffect(() => {
    if (mapInstance && devices.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      devices.map(computeDeviceLocation).forEach(point => bounds.extend(point));
      mapInstance.fitBounds(bounds); // This will zoom as close to the markers on map as possible
      const currentZoom = mapInstance.getZoom();
      if (currentZoom !== undefined) {
        mapInstance.setZoom(Math.min(7, currentZoom));
      }
      // This will prevent from zooming too close (coutry level)
    }
  }, [mapInstance, devices]);

  // This effect compute the devices in bounds each time devices are updated
  useEffect(() => {
    computeDevicesInBounds();
  }, [devices, computeDevicesInBounds]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.listContainer}>
        <NoResults isVisible={!devicesInBounds.length} />
        {devicesInBounds.map(device => {
          const maxSeverity = getMaxSeverity(device);

          return (
            <ListRow
              key={device.id}
              listRowElements={[
                {
                  text: device.description,
                  type: typeMapping[device.category],
                },
              ]}
              onClick={() =>
                history.push(
                  `/device/view/${device.id}?from-devices=true&${filtersSearch}`,
                )
              }
              severity={maxSeverity !== null ? maxSeverity : undefined}
            />
          );
        })}
      </div>
      <ProjectMap
        center={center}
        zoom={FRANCE_REGION.zoom}
        mapContainerClassName={styles.mapContainer}
        onClick={() => setInfoWindowIndex(null)}
        onLoad={setMapInstance}
        onIdle={computeDevicesInBounds}
      >
        <MarkerClusterer
          styles={[
            {
              url: 'cluster.svg',
              width: 36,
              height: 36,
            },
          ]}
        >
          {clusterer =>
            devices.map(device => {
              const numberAlert = countAlerts(device);
              const location = computeDeviceLocation(device);
              const maxSeverity = getMaxSeverity(device);

              return (
                <DeviceMarker
                  {...location}
                  hasInfoWindow={infoWindowIndex === device.id}
                  onClick={() => {
                    setCenter(location);
                    setInfoWindowIndex(
                      infoWindowIndex === device.id ? null : device.id,
                    );
                  }}
                  category={device.category}
                  severity={maxSeverity ? maxSeverity : undefined}
                  key={device.id}
                  name={device.description}
                  position={`${device.site.client.name}, ${device.site.name}`}
                  numberAlert={numberAlert}
                  seeMoreCallback={() =>
                    history.push(
                      `/device/view/${device.id}?from-devices=true&${filtersSearch}`,
                    )
                  }
                  maintainer={device.maintainer}
                  clusterer={clusterer}
                />
              );
            })
          }
        </MarkerClusterer>
      </ProjectMap>
    </div>
  );
}

export default DevicesMap;
