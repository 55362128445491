import React, { HTMLAttributes } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

interface IProps extends HTMLAttributes<HTMLHeadingElement> {
  text: string;
}

const Title: React.FC<IProps> = ({
  children,
  className = '',
  text,
  ...props
}) => {
  return (
    <h1 className={classnames(styles.title, className)} {...props}>
      {text}
    </h1>
  );
};

export { Title };
