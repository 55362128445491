"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ALERT_SEVERITY;

(function (ALERT_SEVERITY) {
  ALERT_SEVERITY["MINOR"] = "MINOR";
  ALERT_SEVERITY["MAJOR"] = "MAJOR";
  ALERT_SEVERITY["CRITICAL"] = "CRITICAL";
})(ALERT_SEVERITY = exports.ALERT_SEVERITY || (exports.ALERT_SEVERITY = {}));

var ALERT_TRIGGER_PERIOD_TYPE;

(function (ALERT_TRIGGER_PERIOD_TYPE) {
  ALERT_TRIGGER_PERIOD_TYPE["OCCURRENCES"] = "occurrences";
  ALERT_TRIGGER_PERIOD_TYPE["MINUTES"] = "minutes";
})(ALERT_TRIGGER_PERIOD_TYPE = exports.ALERT_TRIGGER_PERIOD_TYPE || (exports.ALERT_TRIGGER_PERIOD_TYPE = {}));

var ANOMALY_OPERATOR;

(function (ANOMALY_OPERATOR) {
  ANOMALY_OPERATOR["EQUAL"] = "EQUAL";
  ANOMALY_OPERATOR["SUPERIOR"] = "SUPERIOR";
  ANOMALY_OPERATOR["SUPERIOROREQUAL"] = "SUPERIOROREQUAL";
  ANOMALY_OPERATOR["INFERIOR"] = "INFERIOR";
  ANOMALY_OPERATOR["INFERIOROREQUAL"] = "INFERIOROREQUAL";
})(ANOMALY_OPERATOR = exports.ANOMALY_OPERATOR || (exports.ANOMALY_OPERATOR = {}));