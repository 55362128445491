import React, { ReactNode, useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from 'ui/styles/view.module.scss';
import { SectionTitle } from 'ui/typo/section-title';
import { useParams, useHistory } from 'react-router';
import { getSite } from 'business/site/services/api';
import { ISiteDetailV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { Icon } from 'ui/icons';
import { ProjectMap } from 'ui/google-maps/project-map';
import { formatDateLLL } from 'technical/date';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { useUserContext } from 'business/user/states';
import { deleteSite } from '../../services/api';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';
import { SimpleMarker } from 'ui/google-maps/markers/simple';
import { Separator } from '../../../../ui/layout/separator';
import { useMediaType } from '../../../../technical/media/hooks';

const SiteView: React.FC<ReactNode> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [site, setSite] = useState<ISiteDetailV1Response>();
  const { user } = useUserContext();
  const { isMobile } = useMediaType();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getSite(id);
          setSite(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  const buttons = (
    <>
      {user &&
        [UserRole.COMPANY_MANAGER, UserRole.CLIENT_MANAGER].includes(
          user.role,
        ) && (
          <ModifyButton
            onClick={() => history.push(`/site/modify/${id}`)}
            className={styles.modifyButton}
          >
            {t('company.view.modify')}
          </ModifyButton>
        )}
      {user && user.role === UserRole.COMPANY_MANAGER && (
        <DeleteButton
          onClick={async () => {
            try {
              if (window.confirm(t('site.delete.confirm'))) {
                await deleteSite(String(id));
                redirectWithSuccess('/sites', 'site.delete.success');
              }
            } catch (e) {
              redirectWithError('/sites', 'site.delete.error');
            }
          }}
          className={styles.modifyButton}
        >
          {t('company.view.delete')}
        </DeleteButton>
      )}
    </>
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {site ? (
            <>
              <SectionTitle text={site.name} />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>
              {!isMobile && <Separator className={styles.separator} />}

              <div className={styles.panelContainer}>
                <div className={styles.leftPanel}>
                  <div>
                    <div className={styles.rowIconText}>
                      <Icon width={16} height={16}>
                        location
                      </Icon>
                      <div className={styles.details}>
                        {`Lat:${site.location.x}\nLong:${site.location.y}`}
                      </div>
                    </div>

                    <div className={styles.rowIconText}>
                      <Icon width={16} height={16}>
                        clock
                      </Icon>
                      <div className={styles.details}>
                        {`${t('common.created')} ${formatDateLLL(
                          site.creationTime,
                        )} \n${t('common.updated')} ${formatDateLLL(
                          site.updateTime,
                        )}`}
                      </div>
                    </div>
                  </div>
                </div>
                <ProjectMap
                  mapContainerClassName={styles.rightPanel}
                  center={{
                    lat: site.location.x,
                    lng: site.location.y,
                  }}
                  zoom={12}
                >
                  <SimpleMarker lat={site.location.x} lng={site.location.y} />
                </ProjectMap>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default SiteView;
