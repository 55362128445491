import React from 'react';
import { useHistory } from 'react-router';
import styles from './index.module.scss';
import classNames from 'classnames';

type LinkProps = {
  children: React.ReactNode;
  to: string;
  className?: string;
};

const Link = ({ children, to, className }: LinkProps) => {
  const history = useHistory();

  return (
    <button
      className={classNames(styles.button, className)}
      type="button"
      onClick={() => history.push(to)}
    >
      {children}
    </button>
  );
};

export default Link;
