"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var UserErrorMessages;

(function (UserErrorMessages) {
  UserErrorMessages["INVALID_CREDENTIALS"] = "Invalid user credentials";
  UserErrorMessages["COMPARE_PASSWORDS"] = "New and confirmed password do not match";
})(UserErrorMessages = exports.UserErrorMessages || (exports.UserErrorMessages = {})); // add extra jwt configuration options here


var UserTokenConfiguration;

(function (UserTokenConfiguration) {
  UserTokenConfiguration["ISSUER"] = "Express TS Skeleton";
  UserTokenConfiguration["SUBJECT"] = "Secured authentication";
})(UserTokenConfiguration = exports.UserTokenConfiguration || (exports.UserTokenConfiguration = {}));

exports.UNAUTHORIZED_USER_MESSAGE_KEY = 'unauthorized_user';
exports.FORBIDDEN_USER_MESSAGE_KEY = 'forbidden';
exports.NOT_FOUND_USER_MESSAGE_KEY = 'user.not_found';