import { AxiosInstance, AxiosResponse } from 'axios';

function handleTimeout(error: any) {
  if (error && error.message && error.message.match(/timeout/)) {
    // Handle timeout error
    console.warn(
      'Timeout detected, you can handle it on the timeout interceptor in network module',
    );
  }

  return Promise.reject(error);
}

const responseHandler: [
  (
    value: AxiosResponse<any>,
  ) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
  (error: any) => any,
] = [response => response, error => handleTimeout(error)];

function addTimeoutInterceptors(instance: AxiosInstance) {
  const interceptor = instance.interceptors.response.use(...responseHandler);

  return () => instance.interceptors.response.eject(interceptor);
}

export default addTimeoutInterceptors;
