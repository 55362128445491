import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible: boolean;
}

export function NoResults({ isVisible }: Props) {
  const { t } = useTranslation();

  return isVisible ? (
    <p className={styles.text}>{t('common.noResults')}</p>
  ) : null;
}
