import React, { useState, useCallback } from 'react';
import { Modal } from 'ui/modal';

type ContentType = React.ReactElement | null;
export interface ModalState {
  renderModal: ({ content }: { content: ContentType }) => void;
  closeModal: () => void;
}

export const ModalContext = React.createContext<ModalState>({
  renderModal: () => {},
  closeModal: () => {},
});

const initialContent = null;

const useModal = () => {
  const [content, setContent] = useState<ContentType>(initialContent);

  const renderModal = useCallback(
    ({ content }: { content: ContentType }) => setContent(content),
    [],
  );
  const closeModal = useCallback(() => setContent(null), []);

  return { renderModal, closeModal, content };
};

export const ModalProvider: React.FC = ({ children }) => {
  const { renderModal, closeModal, content } = useModal();

  return (
    <ModalContext.Provider value={{ renderModal, closeModal }}>
      <Modal onRequestClose={closeModal} isOpen={!!content}>
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
