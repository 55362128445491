import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/i18n';
import { Router } from 'bootstrap/router';
import ErrorBoundary from 'technical/error-boundary';

import * as serviceWorker from './serviceWorker';
import { UserProvider, UserContext } from 'business/user/states';
import { ModalProvider } from 'technical/providers/modal';
import ReactModal from 'react-modal';
import { LoadScript } from '@react-google-maps/api';
import { Loader } from 'ui/loader';

ReactModal.setAppElement('#root');

ReactDOM.render(
  <ErrorBoundary>
    <UserProvider>
      <ModalProvider>
        <UserContext.Consumer>
          {({ locale }) => (
            <LoadScript
              googleMapsApiKey={String(process.env.REACT_APP_MAP_API_KEY)}
              loadingElement={<Loader />}
              language={locale}
            >
              <Router />
            </LoadScript>
          )}
        </UserContext.Consumer>
      </ModalProvider>
    </UserProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
