import { NotConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/button/button';
import { InputText } from 'ui/form/inputText';
import { Spacer } from 'ui/layout/spacer';
import styles from './index.module.scss';
import { loginValidation } from './service';
import { Routes } from '../../../../bootstrap/router';
import BasePage from '../../../base-page';
import Link from '../../../../ui/button/link';
import { useLocation } from 'react-router';
import { Loader } from 'ui/loader';
import { LogosGroup } from 'ui/logos/logosGroup';

export interface LoginValues {
  login: string;
  password: string;
}

const Login: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { login, isLoading } = useUserContext();
  const { search: sessionExpired } = useLocation();
  const [error, setError] = useState<string | null>(null);

  return (
    <NotConnectedContainer>
      <BasePage>
        <LogosGroup isHeader={false} />
        <Spacer>
          <Spacer pb={20} mw={400}>
            {sessionExpired === '?sessionExpired' && (
              <span className={styles.infoMessage}>
                {t('user.login.sessionExpired')}
              </span>
            )}
            {error ? <span className={styles.infoMessage}>{error}</span> : null}
            <Formik<LoginValues>
              initialValues={{ login: '', password: '' }}
              onSubmit={async values => {
                setError(null);
                try {
                  await login(values);
                } catch (e) {
                  setError(t('user.login.form.error-credentials-invalid'));
                }
              }}
              validate={loginValidation}
            >
              <Form className={styles.casualForm}>
                <Field
                  className={styles.field}
                  name="login"
                  placeholder={t('user.login.form.login.placeholder')}
                  component={InputText}
                />
                <Field
                  className={styles.field}
                  name="password"
                  type="password"
                  placeholder={t('user.login.form.password.placeholder')}
                  component={InputText}
                />
                <div className={styles.actions}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Button type="submit">{t('user.login.form.submit')}</Button>
                  )}
                  <div className={styles.forgotPassword}>
                    <Link to={Routes.ForgotPassword}>
                      {t('user.login.form.forgot-password')}
                    </Link>
                  </div>
                </div>
              </Form>
            </Formik>
          </Spacer>
        </Spacer>
      </BasePage>
    </NotConnectedContainer>
  );
};

export default Login;
