import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Redirect, useHistory, useParams } from 'react-router';
import FormSiteAdd from '../add/form';
import { getSite } from 'business/site/services/api';
import { ISiteDetailV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { Loader } from 'ui/loader';

const SiteModify: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useHistory();
  const { id } = useParams();
  const [site, setSite] = useState<ISiteDetailV1Response>();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getSite(id);
          setSite(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  if (
    user &&
    ![UserRole.COMPANY_MANAGER, UserRole.CLIENT_MANAGER].includes(user.role)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {site ? (
            <FormSiteAdd
              canCreateEntity={
                user && user.role && UserRole.COMPANY_MANAGER === user.role
                  ? true
                  : false
              }
              initialData={{
                clientId: site.client.id,
                name: site.name,
                latitude: site.location.x,
                longitude: site.location.y,
              }}
              modifyId={id}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default SiteModify;
