"use strict";

var _defineProperty = require("/usr/src/app/front/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$MapDeviceToC;

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DeviceTypeNetwork;

(function (DeviceTypeNetwork) {
  DeviceTypeNetwork["SIGFOX"] = "sigfox";
  DeviceTypeNetwork["EMAIL"] = "email";
  DeviceTypeNetwork["CLEODE"] = "cleode";
  DeviceTypeNetwork["ARTS_TELIT"] = "arts_telit";
  DeviceTypeNetwork["MQTT"] = "MQTT";
})(DeviceTypeNetwork = exports.DeviceTypeNetwork || (exports.DeviceTypeNetwork = {}));

var DeviceTypes;

(function (DeviceTypes) {
  DeviceTypes["ArmoireEssonne"] = "armoire_essonne";
  DeviceTypes["Sunplug"] = "sunplug";
  DeviceTypes["NkeAlarm"] = "nke_alarm";
  DeviceTypes["NkeThermostat"] = "nke_thermostat";
  DeviceTypes["NkePressostat"] = "nke_pressostat";
  DeviceTypes["BatteryMyAlarm"] = "battery_myalarm";
  DeviceTypes["Telit"] = "telit";
  DeviceTypes["Zbms"] = "zbms";
  DeviceTypes["Shelter"] = "shelter";
})(DeviceTypes = exports.DeviceTypes || (exports.DeviceTypes = {}));

var ClientName;

(function (ClientName) {
  ClientName["Grolleau"] = "grolleau";
  ClientName["Arts"] = "arts";
  ClientName["Unknown"] = "unknown";
})(ClientName = exports.ClientName || (exports.ClientName = {}));

exports.MapDeviceToClientName = (_exports$MapDeviceToC = {}, _defineProperty(_exports$MapDeviceToC, DeviceTypes.ArmoireEssonne, ClientName.Grolleau), _defineProperty(_exports$MapDeviceToC, DeviceTypes.Sunplug, ClientName.Grolleau), _defineProperty(_exports$MapDeviceToC, DeviceTypes.NkeAlarm, ClientName.Grolleau), _defineProperty(_exports$MapDeviceToC, DeviceTypes.NkeThermostat, ClientName.Grolleau), _defineProperty(_exports$MapDeviceToC, DeviceTypes.NkePressostat, ClientName.Grolleau), _defineProperty(_exports$MapDeviceToC, DeviceTypes.BatteryMyAlarm, ClientName.Arts), _defineProperty(_exports$MapDeviceToC, DeviceTypes.Telit, ClientName.Arts), _defineProperty(_exports$MapDeviceToC, DeviceTypes.Zbms, ClientName.Arts), _defineProperty(_exports$MapDeviceToC, DeviceTypes.Shelter, ClientName.Grolleau), _exports$MapDeviceToC);