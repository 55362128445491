import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import styles from 'ui/styles/add-form.module.scss';
import { InputText } from 'ui/form/inputText';
import { useTranslation } from 'react-i18next';
import { post, updateCompany } from 'business/company/services/api';
import { SectionTitle } from 'ui/typo/section-title';
import { COMPANY_DEFAULT_CATEGORY_DEVICE } from 'common-active-invest-supervision/dist/src/business/company/types';
import { ICompanyV1Response } from 'common-active-invest-supervision/dist/src/business/company/api/v1';
import { Select } from 'ui/form/select';
import { redirectWithSuccess } from 'technical/flash-message';
import { companyAddValidation } from './service';
import { Button } from 'ui/button/button';

export type CompanyAddValues = {
  name: string;
  defaultDeviceCategory: COMPANY_DEFAULT_CATEGORY_DEVICE | string;
  email: string;
  phone: string;
};

export default function FormCompanyAdd(props: {
  onAdded?: (createdCompany: ICompanyV1Response) => void;
  initialData?: CompanyAddValues;
  modifyId?: string;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      <SectionTitle
        text={
          props.modifyId ? t('company.modify.title') : t('company.add.title')
        }
      />

      <Formik
        initialValues={
          props.initialData || {
            name: '',
            defaultDeviceCategory: '',
            email: '',
            phone: '',
          }
        }
        validate={companyAddValidation}
        onSubmit={async values => {
          setLoading(true);
          setError(null);
          try {
            if (props.modifyId) {
              await updateCompany(props.modifyId, values);
              redirectWithSuccess('/companies', 'company.modify.form.success');
            } else {
              const createdCompany = await post(values);
              props.onAdded
                ? props.onAdded(createdCompany)
                : redirectWithSuccess('/companies', 'company.add.form.success');
            }
          } catch (e) {
            setError(t('company.add.form.error'));
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form autoComplete="off">
          <Field
            name="name"
            placeholder={t('company.add.form.name.placeholder')}
            component={InputText}
            className={styles.inputText}
          />
          <Field
            name="defaultDeviceCategory"
            placeholder={t(
              'company.add.form.defaultDeviceCategory.placeholder',
            )}
            component={Select}
            options={Object.values(COMPANY_DEFAULT_CATEGORY_DEVICE).map(
              (defaultDeviceCategory: string) => ({
                value: defaultDeviceCategory,
                label: t(
                  `common.defaultDeviceCategory.${defaultDeviceCategory}`,
                ),
              }),
            )}
            className={styles.inputText}
          />
          <Field
            name="email"
            placeholder={t('company.add.form.email.placeholder')}
            component={InputText}
            type="email"
            className={styles.inputText}
          />
          <Field
            name="phone"
            placeholder={t('company.add.form.phone.placeholder')}
            component={InputText}
            type="tel"
            className={styles.inputText}
          />
          <div className={styles.actions}>
            <Button disabled={loading} type="submit">
              {props.modifyId
                ? t('company.modify.form.submit')
                : t('company.add.form.submit')}
            </Button>
          </div>
          {error !== null && <div className={styles.errors}>{error}</div>}
        </Form>
      </Formik>
    </>
  );
}
