import React from 'react';

interface Props {
  color: string;
}

export function TodoListPen({ color }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M4 6H9.5" stroke={color} strokeMiterlimit="10" />
        <path d="M4 8.5H9.5" stroke={color} strokeMiterlimit="10" />
        <path d="M4 11H9.5" stroke={color} strokeMiterlimit="10" />
        <path d="M4 13.5H9.5" stroke={color} strokeMiterlimit="10" />
        <path d="M3 6H2" stroke={color} strokeMiterlimit="10" />
        <path d="M3 8.5H2" stroke={color} strokeMiterlimit="10" />
        <path d="M3 11H2" stroke={color} strokeMiterlimit="10" />
        <path d="M3 13.5H2" stroke={color} strokeMiterlimit="10" />
        <path
          d="M3.5 2H0.25V15.75H11.25V2H8"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          d="M6.75 1.25V0.25H4.75V1.25H3.75L3.25 3.25H8.25L7.75 1.25H6.75Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          d="M15.75 0.75V13.25L14.75 15.25L13.75 13.25V0.75H15.75Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path d="M13.75 1.75H12.75V4.25" stroke={color} strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
