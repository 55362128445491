import axios from 'axios';
import { API_HOST, API_PATH, API_TIMEOUT } from './config';
import addAuthInterceptors from './interceptors/authorization';
import addTimeoutInterceptors from './interceptors/timeout';
import add404Interceptors from './interceptors/404';
import add401Interceptors from './interceptors/401';

if (!API_HOST) {
  console.warn(
    'You must fill in the API_HOST constant in the network module to manage HTTP Client request',
  );
}

const instance = axios.create({
  baseURL: `${API_HOST}${API_PATH}`,
  timeout: API_TIMEOUT,
  validateStatus: (status: number) => status >= 200 && status < 500,
  withCredentials: true,
});

addAuthInterceptors(instance);
addTimeoutInterceptors(instance);
add404Interceptors(instance);
add401Interceptors(instance);

export { instance as axios };
