import validate from 'validate.js';
import { LoginValues } from '..';
import { REQUIRED } from '../../../../../technical/validation/rules';
import { i18n } from '../../../../../bootstrap/i18n';

export const loginValidation = ({ login, password }: LoginValues) => {
  return validate(
    { login, password },
    {
      login: REQUIRED(i18n.t('user.login.form.login.required')),
      password: REQUIRED(i18n.t('user.login.form.password.required')),
    },
  );
};
