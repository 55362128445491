"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ALARM_STATUS;

(function (ALARM_STATUS) {
  ALARM_STATUS["OPEN_NOT_NOTIFY"] = "OPEN_NOT_NOTIFY";
  ALARM_STATUS["OPEN_NOT_ACKNOWLEDGED"] = "OPEN_NOT_ACKNOWLEDGED";
  ALARM_STATUS["OPEN_ACKNOWLEDGED"] = "OPEN_ACKNOWLEDGED";
  ALARM_STATUS["CLOSED"] = "CLOSED";
})(ALARM_STATUS = exports.ALARM_STATUS || (exports.ALARM_STATUS = {}));

var ALARM_ACTION;

(function (ALARM_ACTION) {
  ALARM_ACTION["OPEN"] = "OPEN";
  ALARM_ACTION["CLOSE"] = "CLOSE";
  ALARM_ACTION["DO_NOTHING"] = "DO_NOTHING";
})(ALARM_ACTION = exports.ALARM_ACTION || (exports.ALARM_ACTION = {}));

var ALARM_ANOMALY_STATE;

(function (ALARM_ANOMALY_STATE) {
  ALARM_ANOMALY_STATE["UP"] = "UP";
  ALARM_ANOMALY_STATE["DOWN"] = "DOWNN";
  ALARM_ANOMALY_STATE["IGNORE"] = "IGNORE";
})(ALARM_ANOMALY_STATE = exports.ALARM_ANOMALY_STATE || (exports.ALARM_ANOMALY_STATE = {}));

var REDIS_ACTION;

(function (REDIS_ACTION) {
  REDIS_ACTION["ADD"] = "ADD";
  REDIS_ACTION["REMOVE"] = "REMOVE";
  REDIS_ACTION["IGNORE"] = "IGNORE";
})(REDIS_ACTION = exports.REDIS_ACTION || (exports.REDIS_ACTION = {}));