import React, { HTMLAttributes, ReactNode, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  contentClassName?: string;
}

const Page = ({
  children,
  header,
  footer,
  contentClassName,
  ...props
}: IProps) => {
  const footerRef = useRef<HTMLElement>(null);

  return (
    <div className={styles.page} {...props}>
      {header ? <div className={styles.header}>{header}</div> : null}
      <div
        className={classNames(styles.content, contentClassName)}
        style={{
          marginBottom: footerRef.current ? footerRef.current.clientHeight : 0,
        }}
      >
        {children}
      </div>
      {footer ? (
        <footer className={styles.footer} ref={footerRef}>
          {footer}
        </footer>
      ) : null}
    </div>
  );
};

export { Page };
