import React, { useState, useEffect } from 'react';
import BasePage from '../../../base-page';
import styles from 'ui/styles/list.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import { useTranslation } from 'react-i18next';

import { useMediaType } from 'technical/media/hooks';
import { Modal } from 'ui/modal';
import { Routes } from 'bootstrap/router';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { listDeviceTypes } from 'business/device-type/services/api';

import { useUserContext } from 'business/user/states';
import { Redirect, useHistory } from 'react-router';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Paginator } from 'ui/paginator';
import { formatDateLLL } from 'technical/date';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { FlashMessage } from 'ui/flash-message';
import { Button } from 'ui/button/button';
import { AddButton } from 'ui/button/add-button';

const DeviceTypeList: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();
  const [deviceTypes, setDeviceTypes] = useState<IDeviceTypeV1Response[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const { isMobile } = useMediaType();
  const { user } = useUserContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getDeviceTypes() {
      try {
        setIsFetching(true);
        const { items, metadata } = await listDeviceTypes({ page, limit: 5 });
        setDeviceTypes(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getDeviceTypes();
  }, [page]);

  const authorizedUserGroups = [
    UserRole.COMPANY_MANAGER,
    UserRole.GROUP_MANAGER,
    UserRole.GROUP_OBSERVER,
    UserRole.COMPANY_OBSERVER,
  ];
  if (user && !authorizedUserGroups.includes(user.role)) {
    return <Redirect to="/" />;
  }

  const authorizedToAdd = [UserRole.COMPANY_MANAGER];
  const buttons =
    user && authorizedToAdd.includes(user.role) ? (
      <>
        <AddButton onClick={() => history.push(Routes.DeviceTypeAdd)}>
          {t('devicetype.list.add')}
        </AddButton>
      </>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <SectionTitle text={t('devicetype.list.title')} />
        <div>
          <div className={styles.actionContainer}>{!isMobile && buttons}</div>

          <Loader isLoading={isFetching} />

          <NoResults isVisible={!isFetching && !deviceTypes.length} />
          <div style={{ marginBottom: '1em' }}>
            <FlashMessage />
          </div>

          {deviceTypes.map(deviceType => (
            <Row
              key={deviceType.id}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: deviceType.name,
                  subText: deviceType.network,
                  flex: 2,
                },
                {
                  icon: 'graph',
                  subText: deviceType.dashboardUrl,
                  flex: 2,
                  label: t('devicetype.list.grafanaId'),
                },
                {
                  subText: `${t('common.created')} ${formatDateLLL(
                    deviceType.creationTime,
                  )} \n${t('common.updated')} ${formatDateLLL(
                    deviceType.updateTime,
                  )}`,
                  flex: 2,
                },
              ]}
              onClick={() => history.push(`/device-type/view/${deviceType.id}`)}
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} totalPage={totalPage} page={page} />
          )}
        </div>
        <Modal
          isOpen={searchModalOpen}
          onRequestClose={() => setSearchModalOpen(false)}
        >
          <Button
            className={styles.buttonModal}
            onClick={() => history.push(Routes.DeviceTypeAdd)}
          >
            {t('devicetype.list.add')}
          </Button>
        </Modal>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceTypeList;
