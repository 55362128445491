import React from 'react';

import battery from './resources/battery.svg';
import bell from './resources/bell.png';
import cabinet from './resources/cabinet.svg';
import chevronRight from './resources/chevron-right.svg';
import filter from './resources/filter.png';
import lamp from './resources/lamp.svg';
import timeError from './resources/time-error.png';
import phone from './resources/phone.svg';
import location from './resources/location.svg';
import notifications from './resources/notifications.svg';
import account from './resources/account.png';
import help from './resources/help.svg';
import menu from './resources/menu.svg';
import chartPie from './resources/chart-pie.svg';
import user from './resources/user.svg';
import settings from './resources/settings.svg';
import arrowLeft from './resources/arrow-left.svg';
import arrowRight from './resources/arrow-right.svg';
import graph from './resources/graph.svg';
import clock from './resources/clock.svg';
import email from './resources/email.svg';
import check from './resources/check.svg';
import cross from './resources/cross.svg';
import mag from './resources/mag.svg';

interface IProps {
  children: string;
  width: number;
  height: number;
  color?: string;
}

const sources: { [key: string]: any } = {
  account,
  battery,
  bell,
  cabinet,
  filter,
  help,
  lamp,
  notifications,
  menu,
  user,
  settings,
  phone,
  graph,
  clock,
  email,
  check,
  cross,
  location,
  mag,

  'chevron-right': chevronRight,
  'time-error': timeError,
  'chart-pie': chartPie,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
};

const Icon = ({ children, width, height, color }: IProps) => {
  if (typeof sources[children] === 'function') {
    return (
      <>
        {sources[children]({
          width,
          height,
          color,
        })}
      </>
    );
  }

  const sizeStyle = {
    width,
    height,
  };

  return sources[children] ? (
    <img style={sizeStyle} src={sources[children]} alt={children} />
  ) : null;
};

export { Icon };
