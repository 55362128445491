import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import logger from 'technical/logger';
import {
  getUserMe,
  login as requestLogin,
  logout as requestLogout,
} from '../services/api';
import { LoginValues } from '../pages/login';
import {
  removeSession,
  saveSession,
  getSession,
} from '../services/sessionStore';
import { IUserDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { i18n } from 'bootstrap/i18n';
import moment from 'moment';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';

export enum CompanyLogo {
  ARTS_ENERGY,
  GROLLEAU,
  ACTIVE_INVEST,
  OTHER,
}

enum CompanyLogoDomain {
  ARTS_ENERGY = 'arts-energy.com',
  GROLLEAU = 'grolleau.fr',
  ACTIVE_INVEST = 'active-invest.fr',
}

enum CompanyName {
  GROLLEAU = 'Grolleau',
  ARTS_ENERGY = 'Arts',
}

function getCompanyLogoFromEmail(user: IUserDetailedV1Response): CompanyLogo {
  const highCompanyRoles = [
    UserRole.COMPANY_OBSERVER,
    UserRole.COMPANY_MANAGER,
    UserRole.CLIENT_MANAGER,
    UserRole.CLIENT_OBSERVER,
  ];

  if (highCompanyRoles.includes(user.role)) {
    const companyName = user.company ? user.company.name : '';
    switch (companyName) {
      case CompanyName.GROLLEAU:
        return CompanyLogo.GROLLEAU;
      case CompanyName.ARTS_ENERGY:
        return CompanyLogo.ARTS_ENERGY;
      default:
        return CompanyLogo.ACTIVE_INVEST;
    }
  }

  const domain = user.email ? user.email.split('@')[1] : '';
  switch (domain) {
    case CompanyLogoDomain.ARTS_ENERGY:
      return CompanyLogo.ARTS_ENERGY;
    case CompanyLogoDomain.GROLLEAU:
      return CompanyLogo.GROLLEAU;
    case CompanyLogoDomain.ACTIVE_INVEST:
      return CompanyLogo.ACTIVE_INVEST;
    default:
      return CompanyLogo.OTHER;
  }
}

interface UserContext {
  isConnected: boolean;
  isLoading: boolean;
  logout: () => void;
  login: (values: LoginValues) => void;
  user: IUserDetailedV1Response | null;
  locale: string;
  setLocale: (l: string) => void;
  companyLogo: CompanyLogo;
}

export const UserContext = createContext<UserContext>({
  isConnected: false,
  isLoading: false,
  logout: () => {},
  login: () => {},
  user: null,
  locale: 'en',
  setLocale: () => {},
  companyLogo: CompanyLogo.OTHER,
});

function useUserStateProvider() {
  const [bearer, setBearer] = useState<null | string>(getSession());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUserDetailedV1Response | null>(null);
  const [locale, setLocale] = useState<string>('en');
  const [companyLogo, setCompanyLogo] = useState<CompanyLogo>(
    CompanyLogo.OTHER,
  );

  useEffect(() => {
    if (bearer) {
      setIsLoading(true);
      getUserMe(bearer)
        .then(newUser => {
          setUser(newUser.data);

          if (newUser.data.locale) {
            setLocale(newUser.data.locale);
          }

          if (newUser.data.email || newUser.data.company) {
            const userCompany = getCompanyLogoFromEmail(newUser.data);
            setCompanyLogo(userCompany);
          }
        })
        .catch(() => {
          setUser(null);
          // Error during user fetch: do nothing
        })
        .finally(() => setIsLoading(false));
    }
    // tips: explicit bearer as a dependency to refetch user when connecting
  }, [bearer]);

  useEffect(() => {
    i18n.changeLanguage(locale);
    moment.locale(locale);
  }, [locale]);

  const logout = useCallback(async () => {
    await requestLogout();
    setBearer(null);
    setUser(null);
    removeSession();
  }, [setBearer]);

  const login = useCallback(
    async (values: LoginValues) => {
      try {
        setIsLoading(true);
        const { sessionId, redirect } = await requestLogin(values);
        if (redirect) {
          window.location.assign(redirect);
        }
        if (sessionId) {
          setBearer(sessionId);
          saveSession(sessionId);
        }
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [setBearer],
  );

  return {
    bearer,
    isLoading,
    logout,
    login,
    user,
    locale,
    setLocale,
    companyLogo,
  };
}

export const UserProvider: React.FC = ({ children }) => {
  const {
    bearer,
    isLoading,
    logout,
    login,
    user,
    locale,
    setLocale,
    companyLogo,
  } = useUserStateProvider();
  logger.info('UserProvider', { isLoading });

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        logout,
        login,
        locale,
        setLocale,
        companyLogo,
        isConnected: !!bearer,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
