import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { patchUserLocale } from 'business/user/services/api';
import { useUserContext } from 'business/user/states';
import styles from './index.module.scss';

const LocaleButton: React.FC<{
  locale: string;
  text: string;
}> = props => {
  const { i18n } = useTranslation();
  const { user, setLocale } = useUserContext();
  const { locale, text } = props;

  return (
    user && (
      <button
        className={
          i18n.language === locale
            ? classnames(styles.localeButton, styles.activeLocaleButton)
            : styles.localeButton
        }
        onClick={async () => {
          try {
            const { locale: newLocale } = await patchUserLocale(
              user.id,
              locale,
            );
            if (newLocale) {
              setLocale(newLocale);
            }
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {text}
      </button>
    )
  );
};

export default LocaleButton;
