import React from 'react';

export function Indicator() {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.77975 0.303741C1.37261 -0.101247 0.7125 -0.101247 0.305358 0.303741C-0.101785 0.708729 -0.101785 1.36534 0.305358 1.77033L6.2628 7.69626C6.66995 8.10125 7.33005 8.10125 7.7372 7.69626L13.6946 1.77033C14.1018 1.36534 14.1018 0.708729 13.6946 0.303741C13.2875 -0.101247 12.6274 -0.101247 12.2203 0.303741L6.99715 5.49921L1.77975 0.303741Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
}
