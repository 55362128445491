import {
  IDeviceDetailedV1Response,
  IDeviceV1ResponseLight,
} from 'common-active-invest-supervision/dist/src/business/device/api/v1';

export function computeDeviceLocation(
  device: IDeviceDetailedV1Response | IDeviceV1ResponseLight,
): { lat: number; lng: number } {
  return {
    lat: (device.location || device.site.location).x,
    lng: (device.location || device.site.location).y,
  };
}
