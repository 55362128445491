import React from 'react';
import { GrolleauLogo } from '../grolleau';
import styles from './index.module.scss';

interface Props {
  isHeader: boolean;
}

export const LogosGroup = ({ isHeader }: Props) => {
  if (!isHeader) {
    return (
      <div className={styles.logos}>
        <div className={styles.logosFirstRow}>
          <GrolleauLogo width={100} />
        </div>
        <div className={styles.supervisionFont}>SUPERVISION</div>
      </div>
    );
  }

  return (
    <div className={styles.firstRow}>
      <div style={{ paddingRight: 2 }}>
        <GrolleauLogo width={50} />
      </div>
    </div>
  );
};
