import { AxiosInstance, AxiosResponse } from 'axios';

function handle404(response: AxiosResponse<any>) {
  if (response && response.status && response.status === 404) {
    // Handle 404 error
    console.warn(
      '404 detected, you can handle it on the 404 interceptor in network module',
    );
  }

  return response;
}

const responseHandler: [
  (
    value: AxiosResponse<any>,
  ) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
  (error: any) => any,
] = [response => handle404(response), error => Promise.reject(error)];

function add404Interceptors(instance: AxiosInstance) {
  const interceptor = instance.interceptors.response.use(...responseHandler);

  return () => instance.interceptors.response.eject(interceptor);
}

export default add404Interceptors;
