import validate from 'validate.js';
import { ForgotPasswordValues } from '..';
import { EMAIL } from '../../../../../technical/validation/rules';
import { i18n } from '../../../../../bootstrap/i18n';

export const forgotPasswordValidation = ({ email }: ForgotPasswordValues) => {
  return validate(
    { email },
    {
      email: EMAIL(i18n.t('user.forgot-password.form.email.required')),
    },
  );
};
