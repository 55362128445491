import React from 'react';

interface Props {
  color: string;
}

export function Plus({ color }: Props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.423828 5.064H5.48783V0H8.51183V5.064H13.5758V8.088H8.51183V13.152H5.48783V8.088H0.423828V5.064Z"
        fill={color}
      />
    </svg>
  );
}
