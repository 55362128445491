import { AxiosInstance, AxiosResponse } from 'axios';
import { removeSession } from 'business/user/services/sessionStore';
import { UNAUTHORIZED_USER_MESSAGE_KEY } from 'common-active-invest-supervision/dist/src/business/user';

function handle401(response: AxiosResponse) {
  if (
    response.status === 401 &&
    response.data.message === UNAUTHORIZED_USER_MESSAGE_KEY
  ) {
    removeSession();
    // We do not have access to the Router in this context.
    // Shouldn't be a problem since the session is starting over.
    window.location.href = '/login?sessionExpired';
  }

  return response;
}

const responseHandler: [
  (
    value: AxiosResponse<any>,
  ) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
  (error: any) => any,
] = [response => handle401(response), error => handle401(error)];

function add401Interceptors(instance: AxiosInstance) {
  const interceptor = instance.interceptors.response.use(...responseHandler);

  return () => instance.interceptors.response.eject(interceptor);
}

export default add401Interceptors;
