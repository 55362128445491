import React, { ReactNode, useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from 'ui/styles/view.module.scss';
import { SectionTitle } from 'ui/typo/section-title';

import { useParams, useHistory, Redirect } from 'react-router';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import {
  getDeviceType,
  deleteDeviceType,
} from 'business/device-type/services/api';
import { Icon } from 'ui/icons';
import { formatDateLLL } from 'technical/date';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';

const DeviceTypeView: React.FC<ReactNode> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [deviceType, setDeviceType] = useState<IDeviceTypeV1Response>();

  const history = useHistory();
  const { user } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getDeviceType(id);
          setDeviceType(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  const authorizedToView = [
    UserRole.COMPANY_MANAGER,
    UserRole.COMPANY_OBSERVER,
    UserRole.GROUP_MANAGER,
    UserRole.GROUP_OBSERVER,
  ];
  if (user && !authorizedToView.includes(user.role)) {
    return <Redirect to="/" />;
  }

  const authorizedToAdd = [UserRole.COMPANY_MANAGER];
  const buttons =
    user && authorizedToAdd.includes(user.role) ? (
      <>
        <ModifyButton
          className={styles.modifyButton}
          onClick={() => history.push(`/device-type/modify/${id}`)}
        >
          {t('common.modify')}
        </ModifyButton>
        <DeleteButton
          className={styles.modifyButton}
          onClick={async () => {
            try {
              if (window.confirm(t('devicetype.delete.confirm'))) {
                await deleteDeviceType(String(id));
                redirectWithSuccess(
                  '/device-types',
                  'devicetype.delete.success',
                );
              }
            } catch (e) {
              redirectWithError('/device-types', 'devicetype.delete.error');
            }
          }}
        >
          {t('common.delete')}
        </DeleteButton>
      </>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={history.goBack} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {deviceType ? (
            <>
              <SectionTitle text={deviceType.name} />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>

              <div className={styles.panelContainer}>
                <div>
                  <div
                    className={styles.rowIconText}
                    title={t('devicetype.view.network')}
                  >
                    <Icon width={16} height={16}>
                      help
                    </Icon>
                    <div className={styles.details}>{deviceType.network}</div>
                  </div>

                  <div
                    className={styles.rowIconText}
                    title={t('devicetype.view.grafana')}
                  >
                    <Icon width={16} height={16}>
                      graph
                    </Icon>
                    <div className={styles.details}>
                      {deviceType.dashboardUrl}
                    </div>
                  </div>

                  <div className={styles.rowIconText}>
                    <Icon width={16} height={16}>
                      clock
                    </Icon>
                    <div className={styles.details}>
                      {`${t('common.created')} ${formatDateLLL(
                        deviceType.creationTime,
                      )} \n${t('common.updated')} ${formatDateLLL(
                        deviceType.updateTime,
                      )}`}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceTypeView;
