const SESSION_KEY = 'session';

export const saveSession = (sessionId: string) => {
  window.localStorage.setItem(SESSION_KEY, sessionId);
};

export const removeSession = () => {
  window.localStorage.removeItem(SESSION_KEY);
};

export const getSession = () => {
  return window.localStorage.getItem(SESSION_KEY);
};
