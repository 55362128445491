import React, { HTMLAttributes } from 'react';
import styles from './index.module.scss';

interface IProps extends HTMLAttributes<HTMLHeadElement> {
  pt?: number | string;
  pb?: number | string;
  pl?: number | string;
  pr?: number | string;
  mw?: number | string;
  mh?: number | string;
  padding?: number | string;
  paddingVertical?: number | string;
  paddingHorizontal?: number | string;
}

const Spacer: React.FC<IProps> = ({ children, ...props }) => {
  const wrapperStyles = {
    paddingTop: props.pt || props.paddingVertical || props.padding,
    paddingBottom: props.pb || props.paddingVertical || props.padding,
  };
  const contentStyles = {
    paddingLeft: props.pl || props.paddingHorizontal || props.padding,
    paddingRight: props.pr || props.paddingHorizontal || props.padding,
    maxWidth: props.mw,
    maxHeight: props.mh,
  };

  return (
    <div className={styles.spacer} {...props} style={wrapperStyles}>
      <div className={styles.content} style={contentStyles}>
        {children}
      </div>
    </div>
  );
};

export { Spacer };
