import React from 'react';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import none from './../icons/resources/alert-ok.svg';
import minor from './../icons/resources/alert-minor.svg';
import major from './../icons/resources/alert-major.svg';
import critical from './../icons/resources/alert-critical.svg';

interface Props {
  severity?: ALERT_SEVERITY;
  className?: string;
}

const iconMapping = {
  [ALERT_SEVERITY.CRITICAL]: critical,
  [ALERT_SEVERITY.MAJOR]: major,
  [ALERT_SEVERITY.MINOR]: minor,
};

export function SeverityBubble({ severity, className }: Props) {
  return (
    <img
      className={className}
      src={severity ? iconMapping[severity] : none}
      alt={severity}
    />
  );
}
