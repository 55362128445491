import { axios } from '../../../technical/network';
import { DeviceTypeAddValues } from '../pages/add';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { stringify } from 'query-string';

const DEVICETYPE_POST_REQUEST_FAILED = 'DEVICETYPE_POST_REQUEST_FAILED';
export async function post(values: DeviceTypeAddValues) {
  const response = await axios.post<IDeviceTypeV1Response>(
    '/deviceType',
    values,
  );
  if (response.status !== 201) {
    throw new Error(DEVICETYPE_POST_REQUEST_FAILED);
  }

  return response.data;
}

export async function getDeviceType(
  id: string,
): Promise<IDeviceTypeV1Response> {
  const response = await axios.get(`/deviceType/${id}`);

  return response.data;
}

export async function updateDeviceType(
  id: string,
  values: DeviceTypeAddValues,
): Promise<void> {
  const response = await axios.put(`/deviceType/${id}`, values);
  if (response.status !== 200) {
    throw new Error();
  }
}

const DEVICETYPE_DELETE_REQUEST_FAILED = 'DEVICETYPE_DELETE_REQUEST_FAILED';
export async function deleteDeviceType(id: string): Promise<void> {
  const response = await axios.delete<void>(`/deviceType/${id}`);
  if (response.status !== 204) {
    throw new Error(DEVICETYPE_DELETE_REQUEST_FAILED);
  }
}

export async function listDeviceTypes(params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: IDeviceTypeV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    page: params.page ? params.page : 1,
    limit: params.limit ? params.limit : undefined,
  };
  const stringifiedQuery = stringify(filter);

  const response = await axios.get(
    `/deviceTypes${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}
