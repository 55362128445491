import { useEffect, useState } from 'react';
import { WindowSize } from './../types';
import { isTablet, isMobile, isUnderDesktop } from '../services';

function getWindowSize(): WindowSize {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => setWindowSize(getWindowSize());
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export function useMediaType() {
  const windowSize = useWindowSize();

  return {
    isTablet: isTablet(windowSize, window),
    isMobile: isMobile(windowSize),
    isUnderDesktop: isUnderDesktop(windowSize),
  };
}
