import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'ui/typo/title';
import { Spacer } from 'ui/layout/spacer';
import styles from './index.module.scss';
import BasePage from '../../../base-page';

const NoMatch: React.FC<React.ReactNode> = () => {
  const { t } = useTranslation();

  return (
    <BasePage>
      <Spacer pt={140} pb={20} mw={1200}>
        <Title text={t('404.title')} className={styles.title} />
      </Spacer>
    </BasePage>
  );
};

export default NoMatch;
