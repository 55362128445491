import classnames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface IProps {
  isLoading?: boolean;
  className?: string;
}

export const Loader: React.FC<IProps> = ({
  children,
  isLoading = true,
  className = '',
  ...props
}) => {
  return isLoading ? (
    <div className={classnames(styles.container, className)} {...props}>
      <div className={styles.loader}></div>
    </div>
  ) : null;
};
